export const detail = {

	// ms naucne
	'vedecke-pokusy': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/vedecke-pokusy.png" alt="" /></p>
			<h2 style="color: #2e2a4d">Vědecké pokusy Kamarád</h2>
			<p><strong>Jsou Vaše děti zvídavé a rády objevují něco nového? Právě pro ně jsme si připravili celoroční kroužek Vědecké
			pokusy Kamarád, kde se naučí zábavnou formou nejrůznější zajímavé pokusy z oblasti chemie, fyziky a přírodovědy.
			Děti si vyzkoušejí práci s mikroskopem a zkumavkami. Vytvoříme si podmořskou sopku, mléčnou duhu, lávovou
			lampu, magnetický sníh, nenewtonovskou kapalinu a mnoho dalšího.</strong></p>

			<h2 style="color: #2e2a4d">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Seznámí se s přírodovědnými, fyzikálními a chemickými pokusy</span></li>
				<li><span>Vyzkoušejí si práci s mikroskopem, zkumavkami a budou bezpečně experimentovat a bádat</span></li>
				<li><span>Učí se základní postupy práce u jednotlivých pokusů</span></li>
				<li><span>Děti se pomocí pokusů naučí nejen objevovat, ale také prohlubovat své chápání</span></li>
				<li><span>Stanou se na okamžik malými vědci a poznají, že věda je zábava a velké dobrodružství</span></li>
			</ul>
		`
	},
	'maly-astronom': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/maly-astronom.png" alt="" /></p>
			<h2 style="color: #2e2a4d">Malý astronom a badatel</h2>
			<p><strong>Jsou Vaše děti zvídavé a s nadšením se zajímají o vesmír, Zemi, Slunce, Měsíc a sluneční soustavu? Chtějí objevovat
			krásy a tajemství nekonečného vesmíru? Pro takové děti jsme si připravili celoroční kroužek Malý astronom
			a badatel, kde se děti dozvědí spoustu zajímavých informací, ale budeme hlavně bádat a vyrábět. Uděláme si
			létající raketu, periskop, vytvoříme si svoji mlhovinu, komety a mnoho dalšího. Tento kroužek se stane pro děti
			nezapomenutelnou cestou za hranice zemské atmosféry.</strong></p>

			<h2 style="color: #2e2a4d">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Vyrobí si spoustu krásných věcí, které si odnesou domů, a budeme bádat</span></li>
				<li><span>Děti budou objevovat krásy a tajemství nekonečného vesmíru</span></li>
				<li><span>Naučí se hravou formou poznávat hvězdy, souhvězdí, komety, meteory, planetky či mlhoviny</span></li>
				<li><span>Zábavnou formou budou prohlubovat své chápání</span></li>
				<li><span>Stanou se malými astronauty a badateli, kroužek bude pro děti velkým dobrodružstvím</span></li>
			</ul>
		`
	},
	'maly-skaut': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/maly-skaut.png" alt="" /></p>
			<h2 style="color: #2e2a4d">MALÝ PŘÍRODOVĚDEC A SKAUT JUNIOR</h2>
			<p><strong>Zahrnuje vás vaše dítko všetečnými otázkami, jak příroda funguje? Milují Vaše děti přírodu? 
			Dokáže hodiny zkoumat broučky, zvířátka a květiny? Tak přesně pro vaše zvídavé děti je náš kroužek Malý přírodovědec 
			a skaut. Kroužek je určen všem dětem, které rády jezdí na tábory, tráví čas v přírodě a chtěly by se na toto 
			téma dozvědět více. Naučí se spoustu skautských praktických, kreativních a rukodělných dovedností v oblasti rybaření, 
			tábornictví, modelování, vědy, vesmíru, ekologie, první pomoci, dopravních značek a dalších krásných témat. 
			Vysvětlíme si, jak celá naše příroda funguje, co se v ní všechno nachází a jak se k ní a v ní máme chovat. 
			Tento krásný kroužek s tématy o rostlinách, houbách, živočichách žíjící na louce, v lese i v řece potěší srdce 
			každého přírodovědce a skauta. Poslechneme si v nahrávkách život v lese, vysvětlíme si, jak a kde žijí někteří 
			živočichové. Budeme podrobněji zkoumat živočichy, houby, byliny, stromy, rozeznávat zpěv ptactva i stopy divé 
			zvěře.Tento kroužek je pro děti velmi přínosný, jelikož není jednostranně zaměřený. Pomáhá dětem upevňovat vztahy 
			v kolektivu, učí je samostatnosti a toleranci ke svému okolí. Děti si vyzkoušejí široké spektrum činností, které 
			uplatní ve svém každodenním životě.</strong></p>

			<h2 style="color: #2e2a4d">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Rozvine jejich manuální zručnost, praktické uvažování a technické myšlení</span></li>
				<li><span>Děti budou vyrábět ze dřeva, plastu, kovu, kůže a jiných rozmanitých materiálů</span></li>
				<li><span>Naučí se poznávat a používat jednoduché nástroje a nářadí za asistence lektora</span></li>
				<li><span>Děti se seznámí s mnoha skautskými dovednostmi, které děti rozvíjejí po všech stránkách</span></li>
				<li><span>Naučí se toleranci, práci v kolektivu a vyrobí si spoustu krásných předmětů, které si odnesou domů</span></li>
			</ul>
		`
	},
	'ms-harry-potter-vitejte-v-bradavicich': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/harry-potter-vitejte-v-bradavicich.png" alt="" /></p>
			<h2 style="color: #2e2a4d">Harry Potter, vítejte v Bradavické škole!</h2>
			<p>Krásný kroužek pro všechny děti, které milují Harryho Pottera a chtějí zažít dobrodružství a spoustu
			legrace. Kroužek plný čar, kouzel a zábavných aktivit, na které děti jen tak nezapomenou. Děti budou
			zařazeny do jednotlivých kolejí podle moudrého klobouku. Na děti čekají hodiny tajných pokusů,
			bylinkářství, kouzelných lektvarů, boudou se učit poznávat magická zvířata, číst ve starodávných
			runách a nebude chybět ani netradiční forma famfrpálu. Vyrobíme si kouzelnický plášť, klobouk,
			hůlku a budeme kouzlit. Děti, očekávejte zvací dopis z Bradavic.
			</p>
		`
	},
	// --------------------

	// ms hudebni
	'fletna': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/fletna.png" alt="" /></p>
			<h2 style="color: #5AAF47">Hra na flétnu</h2>
			<p><strong>Mají Vaše děti rády hudbu a zajímají se o hraní na hudební nástroje? Chcete, aby získaly základy hudební nauky?
			Připravili jsme si pro ně celoroční kroužek hraní na flétnu, kde se děti naučí poznávat noty a budou rozvíjet své
			hudební, rytmické i harmonické cítění. Zobcová flétna je jedním z nejsnáze zvládnutelných hudebních nástrojů.
			Na děti čeká spousta hezkých písniček i doprovodných rytmických cvičení.</strong></p>

			<h2 style="color: #5AAF47">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Rozvine jejich hudební nadání, smysl pro rytmus a harmonii</span></li>
				<li><span>Podpoří správnou techniku dýchání, jejich soustředění a pozornost</span></li>
				<li><span>Seznámí děti se základy hudební nauky formou přizpůsobenou jejich věku</span></li>
				<li><span>Naučí se správně držet hudební nástroj a poznávat noty</span></li>
				<li><span>Naučí se hrát spoustu hezkých písniček a pomůže jim osvojit si doprovodná rytmická cvičení</span></li>
			</ul>
		`
	},
	'zpivame': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/zpivame.png" alt="" /></p>
			<h2 style="color: #5AAF47">Zpíváme s kytarou a djembe</h2>
			<p><strong>Mají Vaše děti rády hudbu a zajímají se o hraní na hudební nástroje? Chcete, aby získaly základy hudební nauky
			a rozvíjely své hudební, rytmické a harmonické cítění? Pro Vaše děti jsme si připravili celoroční kroužek Zpíváme
			s kytarou a djembe, kde se děti zábavnou formou učí základní techniky zpěvu. Zpěv bude doplněn hudebními
			nástroji, například kytarou a africkými bubny djembe, které jsou úžasným hudebním doprovodem a u kterých se
			děti krásně uvolní.</strong></p>

			<h2 style="color: #5AAF47">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Rozvine jejich hudební nadání, smysl pro rytmus a harmonii</span></li>
				<li><span>Podpoří správnou techniku dýchání, jejich soustředění a pozornost</span></li>
				<li><span>Seznámí děti se základy hudební nauky a naučí je poznávat rozdíly mezi sólovým a sborovým zpěvem</span></li>
				<li><span>Na děti čeká pestrý hudební repertoár lidových, moderních a pohádkových písní</span></li>
				<li><span>Hra na djembe harmonizuje mysl a dodá dětem velkou dávku pozitivní energie</span></li>
			</ul>
		`
	},
	// ----------------------------

	// ms tanecni
	'street-dance': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/street-dance.png" alt="" /></p>
			<h2 style="color: #F2915C">Street Dance</h2>
			<p><strong>Milují Vaše děti hudbu a pohyb? Líbí se jim moderní a volné taneční styly? Zajímají se o nevšední a zajímavé taneční
			choreografie? Právě pro ně jsme si připravili celoroční kroužek Street dance, kde se děti seznámí s volnými styly
			různých pouličních tanců. Tyto tance patří mezi moderní formy pohybu a jsou pro děti vynikajícím odreagováním.</strong></p>

			<h2 style="color: #F2915C">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Seznámí se s volnými styly pouličních tanců, jako je hip hop, modern street, just dance a jiné</span></li>
				<li><span>Naučí se spoustu nevšedních a zajímavých tanečních choreografií za doprovodu moderní hudby</span></li>
				<li><span>Rozvinou své taneční schopnosti, pohybové nadání a smysl pro rytmus</span></li>
				<li><span>Naučí se správnému držení těla, koordinaci a zpevní si celé tělo</span></li>
				<li><span>Výuka je přizpůsobena věku dětí, jejich schopnostem a děti při tanci zažijí jedinečný pocit uvolnění</span></li>
			</ul>
		`
	},
	'tanecky': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/tanecky.png" alt="" /></p>
			<h2 style="color: #F2915C">Tanečky s prvky baletu</h2>
			<p><strong>Milují Vaše děti hudbu a pohyb? Chtěly by se na okamžik stát balerínou a naučit se propojit tanec s uměním? Chtěli
			byste, aby se naučily nové taneční styly a zajímavé choreografie? Pro Vaše děti jsme si připravili celoroční kroužek
			Tanečky s prvky baletu, který kombinuje různé taneční styly a zahrnuje především klasický tanec s prvky baletu či
			výrazový tanec, kde se děti naučí skrz pohyb vyjádřit své pocity, myšlenky a příběh.</strong></p>

			<h2 style="color: #F2915C">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Rozvinou své taneční schopnosti, pohybové nadání a osvojí si základy baletní techniky</span></li>
				<li><span>Naučí se spoustu nevšedních choreografií a vyzkoušejí si různé taneční styly</span></li>
				<li><span>Naučí se vyjadřovat své pocity, myšlenky, příběhy skrz pohyb a vnímání rytmu</span></li>
				<li><span>Naučí se správnému držení těla, koordinaci a zpevní si celé tělo</span></li>
				<li><span>Výuka je přizpůsobena věku dětí, jejich schopnostem a probíhá zábavnou formou</span></li>
			</ul>
		`
	},
	'disco': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/disco.png" alt="" /></p>
			<h2 style="color: #F2915C">Disco dance aneb tančíme <br>na moderní hudbu s Kamarádem</h2>
			<p><strong>Milují Vaše děti hudbu a pohyb? Chtěli byste, aby se naučily nové taneční styly a zajímavé choreografie? Připravili
			jsme si pro ně celoroční kroužek Disco dance aneb Tančíme na moderní hudbu s Kamarádem, který kombinuje
			různé moderní taneční styly a je určen všem dětem, které baví tanec a chtějí se v něm dále rozvíjet.</strong></p>

			<h2 style="color: #F2915C">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Rozvinou své taneční schopnosti, pohybové nadání a pomůže jim najít vlastní taneční styl</span></li>
				<li><span>Naučí se spoustu nevšedních choreografií a vyzkoušejí si různé taneční kroky</span></li>
				<li><span>Naučí se vyjadřovat své pocity skrz pohyb a vnímat rytmus</span></li>
				<li><span>Naučí se správnému držení těla, koordinaci a zpevní si celé tělo</span></li>
				<li><span>Výuka je přizpůsobena věku dětí, jejich schopnostem a probíhá zábavnou formou</span></li>
			</ul>
		`
	},
	'zumba': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/zumba.png" alt="" /></p>
			<h2 style="color: #F2915C">Dětská zumba</h2>
			<p><strong>Milují Vaše děti pohyb a hudbu? Chtějí se naučit originální taneční a krokové variace v rytmu latinskoamerické
			hudby? Pro takové děti jsme si připravili celoroční kroužek zumby, kde se skvěle odreagují, seznámí se s novými
			druhy tanců, zlepší svoji fyzickou kondici, pohybově se zdokonalí a přirozenou formou budou pracovat se svou
			energií. Zumba působí úžasně na jejich tělo i mysl.</strong></p>

			<h2 style="color: #F2915C">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Naučí se originální krokové variace a taneční choreografie v rytmu latinskoamerické hudby</span></li>
				<li><span>Naučí se základní kroky salsy, reggaetonu, merengue, cumbie, samby a swingu</span></li>
				<li><span>Zlepší si fyzickou kondici, zdokonalí se ve flexibilitě, koordinaci a obratnosti</span></li>
				<li><span>Děti pracují s přirozenou energií, posilují si sebevědomí, učí se disciplíně a týmové spolupráci</span></li>
				<li><span>Výuka je přizpůsobena věku dětí, jejich schopnostem a přinese dětem skvělý pocit odreagování</span></li>
			</ul>
		`
	},
	// ----------------------------

	// ms vytvarne
	'vytvarne-dilny': {
		color: 'yellow',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/vytvarne-dilny.png" alt="" /></p>
			<h2 style="color: #FCEA0D">Výtvarné dílny</h2>
			<p><strong>Pro Vaše děti jsme si připravili výtvarně obohacující, tvořivý a inspirativní kroužek, ve kterém se děti naučí novým,
			netradičním výtvarným technikám, naučí se pracovat s netradičními materiály, jež nejsou běžnou součástí
			výtvarného kroužku. Děti si rozvinou jemnou motoriku, kreativní myšlení, zapojí vlastní fantazii a vytvoří si krásné
			a originální výrobky, které si odnesou domů.</strong></p>

			<h2 style="color: #FCEA0D">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Vyzkoušejí si techniky decoupage, quilling, drátkování, kašírování, bubble‐up, špachtličkování, košíkování</span></li>
				<li><span>Naučí se kreativně pracovat s nejrůznějšími netradičními materiály</span></li>
				<li><span>Vyzkoušejí si malování na textil, porcelán, kameny, batikování a tvorbu z FIMO hmoty</span></li>
				<li><span>Udělají si například šusťové figurky, voňavá mýdla, razítka a tisky z provázků, upletou si košíček</span></li>
				<li><span>Rozvinou si smyslové vnímání, cit pro estetičnost a manuální zručnost</span></li>
			</ul>
		`
	},
	'tvorive-dilny': {
		color: 'yellow',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/tvorive-dilny.png" alt="" /></p>
			<h2 style="color: #FCEA0D">Tvořivé dílny a výroba šperků</h2>
			<p><strong>Tvoří Vaše děti rády? Přejete si u nich podporovat estetické cítění, rozvíjet jejich manuální zručnost a kreativní
			myšlení? Připravili jsme si pro ně krásný kroužek Tvořivé dílny a výroba šperků, kde si budou rozvíjet jemnou
			motoriku a odnesou si domů mnoho krásných a originálních šperků a jiných výrobků. Kroužek je určen jak pro
			děvčata, tak i pro chlapce, které láká kreativní umělecká činnost.</strong></p>

			<h2 style="color: #FCEA0D">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Kroužek je zaměřen jak na výrobu šperků, tak i na netradiční výtvarné techniky</span></li>
				<li><span>Děti se naučí kreativně pracovat s nejrůznějšími materiály</span></li>
				<li><span>Děti si vyzkoušejí rozličné netradiční výtvarné techniky, kde zapojí svou fantazii</span></li>
				<li><span>Kroužek přirozenou formou rozvíjí estetické cítění, kreativní myšlení, jemnou motoriku
				a manuální zručnost</span></li>
				<li><span>Děti si odnesou domů mnoho krásných a originálních výrobků pro sebe i své blízké</span></li>
			</ul>
		`
	},
	'keramika': {
		color: 'yellow',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/keramika.png" alt="" /></p>
			<h2 style="color: #FCEA0D">Keramika</h2>
			<p><strong>Modelují, tvoří a vyrábějí Vaše děti rády? Přejete si u nich podporovat estetické cítění, rozvíjet jejich fantazii
			a kreativní myšlení? Připravili jsme si pro ně celoroční kroužek keramiky, kde si budou děti rozvíjet jemnou motoriku,
			estetické cítění, naučí se pracovat s keramickou hlínou a odnesou si domů mnoho krásných a netradičních výrobků.</strong></p>

			<h2 style="color: #FCEA0D">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Naučí se základní techniky a postupy modelování</span></li>
				<li><span>Vyzkoušejí si modelování z různých typů keramických hlín a seznámí se s přírodními materiály</span></li>
				<li><span>Naučí se různé umělecké techniky dekorování keramiky</span></li>
				<li><span>Přirozenou formou si rozvinou estetické cítění, kreativní myšlení, jemnou motoriku a manuální zručnost</span></li>
				<li><span>Odnesou si domů mnoho krásných netradičních dárků pro sebe i své blízké</span></li>
			</ul>
		`
	},

	// ms sportovni
	'fotbal': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/fotbal.png" alt="" /></p>
			<h2 style="color: #0086BA">Fotbal pro nejmenší</h2>
			<p><strong>Milují Vaše děti pohyb a mají rády míčové hry? Přejete si rozvíjet jejich pohybové dovednosti a podporovat lásku ke
			sportu? Připravili jsme si pro ně celoroční kroužek fotbalu, který je určen jak chlapcům, tak i dívkám, které se chtějí
			skvěle odreagovat a seznámit se s tímto oblíbeným sportem. Kroužek je zaměřen na přirozený rozvoj sportovní
			všestrannosti, obratnosti, rychlosti, soustředění a týmové spolupráce. Nebudou chybět ani atletická a gymnastická
			cvičení.</strong></p>

			<h2 style="color: #0086BA">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Naučí se základním fotbalovým a pohybovým dovednostem, rozvinou si rychlost, píli a soustředění</span></li>
				<li><span>Součástí pohybové náplně jsou motorická cvičení, která rozvíjejí všeobecné pohybové návyky</span></li>
				<li><span>Kroužek zahrnuje prvky atletiky a gymnastiky, které podporují obratnost a flexibilitu</span></li>
				<li><span>Děti se učí týmové spolupráci, chování fair play a zdravé soutěživosti</span></li>
				<li><span>Získají výbornou pohybovou průpravu, vytvoří si lásku ke sportu a skvěle se odreagují</span></li>
			</ul>
		`
	},
	'gymnastika': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/gymnastika.png" alt="" /></p>
			<h2 style="color: #0086BA">Sportovní gymnastika</h2>
			<p><strong>Milují Vaše děti pohyb? Přejete si rozvíjet jejich pohybové dovednosti a zlepšit jejich fyzickou kondici? Pro Vaše
			děti jsme si připravili celoroční kroužek sportovní gymnastiky, který je zaměřen na přirozený a všestranný
			pohybový rozvoj a je skvělou průpravou k jakémukoliv sportu v budoucnu. Děti se učí nejrůznější gymnastická
			cvičení, nebudou chybět ani atletické prvky a relaxační cvičení.</strong></p>

			<h2 style="color: #0086BA">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Naučí se nejrůznější základní gymnastická cvičení, sestavy a choreografie</span></li>
				<li><span>Na děti čeká cvičení se švihadly, míčem, obručí a stuhami</span></li>
				<li><span>Rozvinou své pohybové dovednosti, zlepší si fyzickou kondici a pružnost těla</span></li>
				<li><span>Naučí se správnému držení těla, koordinaci a obratnosti</span></li>
				<li><span>Získají výbornou pohybovou průpravu, kterou využijí v budoucnu v jakémkoliv sportu</span></li>
			</ul>
		`
	},
	'sportovni-krouzek': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/sportovni-krouzek.png" alt="" /></p>
			<h2 style="color: #0086BA">Sportovní kroužek Kamarád</h2>
			<p><strong>Připravili jsme si pro Vaše děti jedinečný celoroční Sportovní kroužek Kamarád, který je zaměřen na všestranný
			pohybový rozvoj dítěte a je postaven na pestrém mixu gymnastických a atletických cvičení, pohybových her
			a rozvoje osobnosti. Cílem kroužku je v dětech vybudovat přirozenou a hravou formou celoživotní lásku k pohybu
			a ke sportu. Každé dítě obdrží herní plán jednotlivých sportovních disciplín, které budou děti v rámci kroužku
			postupně plnit.</strong></p>

			<h2 style="color: #0086BA">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Hravý a bohatý sportovní program zaměřený na všestranný pohybový rozvoj dětí</span></li>
				<li><span>Děti se učí gymnastickým, atletickým a obecným pohybovým dovednostem</span></li>
				<li><span>Rozvíjejí svou obratnost, rychlost, flexibilitu, přesnost a koordinaci celého těla</span></li>
				<li><span>Učí se zdravé soutěživosti, týmové spolupráci a pravidlům fair play</span></li>
				<li><span>Získají výbornou pohybovou průpravu, vytvoří si lásku ke sportu a skvěle se odreagují</span></li>
			</ul>
		`
	},
	'joga': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/joga.png" alt="" /></p>
			<h2 style="color: #0086BA">Dětská jóga a zdravotní cvičení</h2>
			<p><strong>Připravili jsme si pro Vaše děti celoroční kroužek jógy a zdravotního cvičení, ve kterém se děti naučí vnímat své tělo
			a svůj vnitřní svět. Naučí se koncentrovat a rozvíjet svoji emoční inteligenci. Je to ideální pohyb pro všechny děti,
			který pomáhá aktivní děti zklidnit, vede ke zdravému životnímu stylu a zdravému sebevědomí.</strong></p>

			<h2 style="color: #0086BA">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Naučí se základní jógové pozice a relaxační techniky</span></li>
				<li><span>Naučí se správné koordinaci těla, soustředěnosti a sebeovládání</span></li>
				<li><span>Děti se učí správně dýchat, vnímat své tělo a svůj vnitřní svět</span></li>
				<li><span>Zlepší si fyzickou zdatnost, flexibilitu a rovnováhu těla</span></li>
				<li><span>Aktivní děti pomáhá zklidnit a u tišších dětí budovat zdravé sebevědomí</span></li>
			</ul>
		`
	},
	'karate': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/karate.png" alt="" /></p>
			<h2 style="color: #0086BA">Karate pro nejmenší</h2>
			<p><strong>Připravili jsme si pro Vaše děti celoroční kroužek Karate pro nejmenší, který rozvíjí tělo i mysl a má pozitivní vliv
			na psychický vývoj dětí. Karate je pro děti krásný způsob sebevyjádření a nechává dětskou duši projevit se
			pohybem, dává důraz na přesnost, sebekontrolu a zvládnutí pohybů těla. Náplň našeho kroužku je speciálně
			upravena pro malé děti – je ušitý na míru jejich tělu i duši s ohledem na jejich individuální schopnosti.</strong></p>

			<h2 style="color: #0086BA">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Seznámí se s jednoduchými, základními technikami, cvičením zaměřeným na pozornost a koncentraci</span></li>
				<li><span>Důraz je kladen na osobní rozvoj, jako je komunikace, sebedůvěra, spolupráce a samostatnost</span></li>
				<li><span>Naučí se být trpělivé, vítězit i přijímat prohru a nebudou chybět ani základní pohybové dovednosti</span></li>
				<li><span>Děti se naučí přesnosti, sebekontrole, respektu a odvaze</span></li>
				<li><span>Karate rozvíjí tělo i mysl a má pozitivní vliv na psychický vývoj dítěte</span></li>
			</ul>
		`
	},
	// ----------------------------

	// ms dramaticke
	'hudebne-dramaticky-krouzek': {
		color: 'purple',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/hudebne-dramaticky-krouzek.png" alt="" /></p>
			<h2 style="color: #623F90">Hudebně‐dramatický kroužek</h2>
			<p><strong>Připravili jsme si pro Vaše děti celoroční hudebně‐dramatický kroužek, kde si budou moci rozvíjet komunikační,
			tvůrčí, pohybové a pěvecké dovednosti. Zahrají si spoustu krásných naučných divadelních her a vyzkoušejí si
			mnoho zajímavých rolí. Naučí se pracovat s hlasem, gesty a mimikou. Děti se přirozenou formou naučí překonávat
			trému, rozvíjet svoji fantazii a spolupracovat v kolektivu dětí. Zazpíváme si mnoho sborových i sólových písní
			za doprovodu hudebních nástrojů.</strong></p>

			<h2 style="color: #623F90">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Pomocí různorodých divadelních her a pohádek si vyzkoušejí mnoho krásných rolí</span></li>
				<li><span>Naučí se pracovat s hlasem, gesty a mimikou a přirozenou formou překonávat trému</span></li>
				<li><span>Důraz je kladen na přátelství, vzájemnou spolupráci a rozvoj dramatického cítění</span></li>
				<li><span>Naučí se základům hudební nauky, správnému dýchání a zazpívají si lidové i moderní písničky</span></li>
				<li><span>Děti rozvíjejí svou fantazii, komunikační, tvůrčí a pohybové dovednosti</span></li>
			</ul>
		`
	},
	'loutkove': {
		color: 'purple',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/loutkove.png" alt="" /></p>
			<h2 style="color: #623F90">LOUTKOVÉ A MAŇÁSKOVÉ DIVADLO</h2>
			<p><strong>Pro Vaše děti jsme si připravili krásný kroužek loutkového a maňáskového divadla, kde budou moci rozvíjet své
			komunikační, tvůrčí a pohybové dovednosti. Dále si rozvinou kreativní myšlení a fantazii, ponoří se do světa
			nejznámějších českých pohádek a zahrají si krásná představení s loutkami a maňásky. Děti se přirozenou formou
			naučí překonávat trému a spolupracovat v kolektivu. Vytvoří si vztah k loutkovému divadlu.</strong></p>

			<h2 style="color: #623F90">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Seznámí se s loutkovým divadlem a zahrají si krásné představení s loutkami a maňásky</span></li>
				<li><span>Naučí se pracovat s hlasem, gesty a mimikou a přirozenou formou překonávat trému</span></li>
				<li><span>Důraz je kladen na přátelství, vzájemnou spolupráci a rozvoj dramatického cítění</span></li>
				<li><span>Děti rozvíjejí svou fantazii, komunikační, tvůrčí a pohybové dovednosti</span></li>
				<li><span>Vyzkoušejí si spoustu krásných rolí, ponoří se do světa pohádek a výborně se odreagují</span></li>
			</ul>
		`
	},
	// --------------------------

	// ms jazykove
	'hrava-anglictina': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/hrava-anglictina.png" alt="" /></p>
			<h2 style="color: #CB79AF">Hravá angličtina</h2>
			<p><strong>Pro Vaše děti jsme si připravili celoroční kroužek Hravá angličtina, určený nejmenším dětem, které se s jazykem
			teprve seznamují. Naše lekce jsou jim připraveny na míru s ohledem na jejich věk. Výuka je vedena hravou formou,
			tak aby si děti vybudovaly pozitivní vztah k anglickému jazyku. Kroužek vedou zkušení lektoři, kteří používají
			kvalitní, moderní metody výuky. Naučíme Vaše děti anglicky zábavně, bez stresu a efektivně. Již po několika
			lekcích uvidíte první výsledky.</strong></p>

			<h2 style="color: #CB79AF">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Seznámí děti se základy anglického jazyka hravou a efektivní formou</span></li>
				<li><span>Děti se naučí spoustu slovíček, jednoduché fráze, anglické písničky a básničky</span></li>
				<li><span>Zábavná interaktivní výuka plná her, používání audiovizuálních pomůcek, kartiček, obrázků</span></li>
				<li><span>Děti si vybudují kladný vztah k anglickému jazyku a vytvoří si skvělý základ do budoucna</span></li>
				<li><span>Lekce jsou postaveny tak, aby podporovaly a rozvíjely vnitřní motivaci dětí naučit se nový jazyk</span></li>
			</ul>
		`
	},
	'anglictina-rodily-mluvci': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/anglictina-rodily-mluvci.png" alt="" /></p>
			<h2 style="color: #CB79AF">Angličtina – úroveň rodilý mluvčí</h2>
			<p><strong>Kroužek je určen všem dětem, které již mají základní znalost anglického jazyka. Naše hodiny jsou 
			připraveny na míru s ohledem na věk dětí a jejich dosavadní znalosti. Kroužek vyučují výhradně kvalifikovaní 
			lektoři s certifikátem C2 – úroveň rodilý mluvčí. Naši lektoři vyučují jazyk zábavnou a srozumitelnou formou. 
			Velký důraz je kladen na konverzaci, lektor pro děti připravuje tematické aktivity, které se zaměřují zejména 
			na aktivní komunikaci, správnou výslovnost a zároveň rozvíjejí gramatické a poslechové dovednosti dětí. Děti 
			se naučí novým anglickým frázím, vybudují si bohatou slovní zásobu, odbourají ostych mluvit a vybudují si zdravé 
			sebevědomí, které je pro komunikační dovednosti tolik důležité. Naši lektoři používají výhradně kvalitní výukové 
			materiály a audiovizuální pomůcky.</strong></p>

			<h2 style="color: #CB79AF">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Děti se seznámí se základy anglického jazyka hravou a efektivní formou pod vedením zkušených rodilých mluvčích</span></li>
				<li><span>Děti se naučí spoustu slovíček, jednoduché fráze, anglické písničky a básničky</span></li>
				<li><span>Děti napodobují a opakují, co lektor dělá a říká, učí se tak významům slov</span></li>
				<li><span>Zábavná interaktivní výuka plná her, používání audiovizuálních pomůcek, kartiček, mimiky a gest</span></li>
				<li><span>Jedná se o přirozený a velmi efektivní proces učení, který dá dětem výborné základy do budoucnosti</span></li>
			</ul>
		`
	},
	'logopedie': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/logopedie.png" alt="" /></p>
			<h2 style="color: #CB79AF">Český jazyk s Kamarádem</h2>
			<p><strong>Pro Vaše děti jsme si připravili velice přínosný kroužek českého jazyka s Kamarádem, který je ideální příležitostí na podporu komunikačních dovedností a procvičuje správnou výslovnost českého jazyka, vyjadřování a formulaci myšlenek dětí. Součástí kroužku jsou i dechová cvičení, která napomáhají plynulosti řeči a správné práci s dechem. Kroužek je veden zkušenými lektory speciální pedagogiky a je skvělou podpůrnou metodou komunikačních dovedností, nicméně nenahrazuje práci klinického logopeda. </strong></p>

			<h2 style="color: #CB79AF">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Pomocí říkadel, básniček, písniček a nejrůznějších cvičení vede děti k rozvoji slovní zásoby</span></li>
				<li><span>Součástí každé lekce jsou dechová cvičení, která napomáhají plynulosti řeči</span></li>
				<li><span>Děti seznamujeme se správnou artikulací a dbáme na správnou výslovnost hlásek</span></li>
				<li><span>Pomocí slovních hříček se děti učí formulovat myšlenky</span></li>
				<li><span>Zábavnou a hravou formou děti rozvíjejí komunikační dovednosti českého jazyka, svoji osobnost a učí se týmové spolupráci</span></li>
			</ul>
		`
	},
	'ctenarsky-krouzek': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/ms/detail/ctenarsky-krouzek.png" alt="" /></p>
			<h2 style="color: #CB79AF">Čtenářský kroužek Kamarád</h2>
			<p><strong>Pro Vaše děti jsme si připravili čtenářský kroužek Kamarád, který je ideální příležitostí vést děti již v předškolním věku
			ke čtení a vytvářet u nich pozitivní a aktivní vztah ke knihám. Kroužek je zaměřen na rozvoj řeči dítěte, podporuje
			dětské chápání, pozornost a vnímavost. Děti se učí lépe naslouchat, rozvíjí si schopnost přemýšlet, představivost,
			kreativitu a fantazii. Kroužek se dále zaměřuje na procvičování správné výslovnosti, rozvíjí předčtenářské dovednosti
			a vytváří základy pro budoucí výuku čtení. Součástí kroužku jsou i dechová cvičení, která napomáhají plynulosti řeči.</strong></p>

			<h2 style="color: #CB79AF">Co dětem kroužek přinese</h2>
			<ul>
				<li><span>Budeme si hrát se slovy, naučíme děti správně artikulovat hlásky a seznámíme je s abecedou</span></li>
				<li><span>Dětem budeme předčítat nejrůznější pohádky, příběhy a budeme si společně povídat nad ilustracemi</span></li>
				<li><span>Děti se naučí chápat princip čtení, naslouchat a přirozeně si rozvinou zvídavost, paměť, chápání a představivost</span></li>
				<li><span>Zahrajeme si na malé básníky, budeme tvořit jednoduché rýmy, pořekadla a zahrajeme si scénky, kde děti volně improvizují o dané pohádce</span></li>
				<li><span>Děti si rozvinou fonematický sluch a uvědomění, předčtenářské dovednosti a vytvářejí si pozitivní vztah ke knihám</span></li>
			</ul>
		`
	},

	// zs sportovni
	'vseobecny-sportovni-krouzek': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/vseobecny-sportovni-krouzek.png" alt="" /></p>
			<h2 style="color: #0086BA">VŠESTRANNÝ SPORTOVNÍ KROUŽEK</h2>
			<p>Náš jedinečný sportovní program, který je zaměřen na všestranný pohybový
			rozvoj dítěte, ne jen na jednostrannou specializaci, je postaven na pestrém
			mixu gymnastických a atletických cvičení, pohybových her a rozvoje
			osobnosti. Cílem kroužku je v dětech vybudovat celoživotní lásku k pohybu
			a ke sportu přirozenou a hravou formou, nikoliv náročným drilem. Tento
			typ kroužku je výborným základem pro všechny druhy sportů. Na začátku
			každé dítě obdrží herní plán jednotlivých sportovních disciplín, které budou
			děti v kroužku postupně plnit.</p>
		`
	},
	'sportovni-gymnastika': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/sportovni-gymnastika.png" alt="" /></p>
			<h2 style="color: #0086BA">SPORTOVNÍ GYMNASTIKA</h2>
			<p>Cílem našeho gymnastického kroužku je všeobecný
			a přirozený pohybový rozvoj dětí. V kroužku jsou zahrnuta
			nejrůznější gymnastická cvičení, mezi něž patří kotouly vpřed i vzad, stoje
			na rukou, salto či přemety stranou („hvězdy“), klasický přemet vzad,
			gymnastická chůze doprovázená skoky a obraty. V kroužku nebudou chybět
			cvičení zaměřená na gymnastické posilování, rychlost, obratnost, flexibilitu
			a koordinaci celého těla. Děti zdolávají překážkové dráhy, přelézají, podlézají
			a šplhají. Kroužek zahrnuje i atletické prvky, jako jsou skoková cvičení či atletická
			abeceda, a v neposlední řadě se děti učí správnému dýchání.</p>
		`
	},
	'karate-pro-deti': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/karate-pro-deti.png" alt="" /></p>
			<h2 style="color: #0086BA">KARATE PRO DĚTI</h2>
			<p>Tradiční staré bojové umění beze zbraně, které rozvíjí tělo i mysl a má pozitivní
			vliv na psychický vývoj dětí. Náplň našeho kroužku je speciálně upravena
			pro malé děti, které se s tímto druhem sportu seznamují. Je ušitý na míru
			jejich tělu i duši s ohledem na jejich individuální schopnosti. V kroužku se
			děti seznámí s jednoduchými základními technikami, cvičením zaměřeným
			na pozornost a koncentraci. Do kroužku jsou zapojena i témata osobního
			rozvoje jako komunikace, sebedůvěra, spolupráce a samostatnost. Karate
			je pro děti krásný způsob sebevyjádření a nechává dětskou duši projevit se
			pohybem, dává důraz na přesnost, sebekontrolu a zvládnutí pohybů těla.</p>
		`
	},
	'judo': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/judo.png" alt="" /></p>
			<h2 style="color: #0086BA">JUDO PRO DĚTI</h2>
			<p>Staré bojové umění, které vychází z tradiční japonské filozofie, podporuje všeobecný
			rozvoj dítěte a klade důraz na tradiční hodnoty, jako je zdvořilost, odvaha, respekt, zdravá sebedůvěra,
			sebeovládání a přátelství. Judo bylo organizací UNESCO doporučeno jako nejvhodnější sport pro děti.
			V našem kroužku se budou děti učit vzájemnému respektu a sebeovládání, trpělivosti, naučí se vítězit
			i přijímat prohru, naučí se techniky, jak se bránit. Nebudou chybět základní pohybové dovednosti,
			jako například kotouly, sklopky, hvězdy, přemety, salta, pády, techniky na zemi i v postoji, sebeobrana,
			koordinace těla a jeho ovládání. Děti se naučí i něco z odkazu japonských bojovníků a jejich morálních
			hodnot.</p>
		`
	},
	'zs-fotbal': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-fotbal.png" alt="" /></p>
			<h2 style="color: #0086BA">FOTBAL</h2>
			<p>Nejrozšířenější světový sport. Cílem kroužku je naučit děti nejenom základům
			fotbalu, ale i píli, soustředění a spolupráci s ostatními dětmi. Děti v kroužku
			trénují soustředění, správný pohyb, techniku a zlepší si celkovou fyzickou
			kondici. Jako bonus jsme si pro děti v našem kroužku připravili jedinečnou
			příležitost ve formě <strong>exkurze na fotbalový stadion SK Slavia Praha</strong>, kde
			navštíví muzeum Slavie a <strong>setkají se s prvoligovými fotbalisty</strong>.</p>
		`
	},
	'futsal': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/futsal.png" alt="" /></p>
			<h2 style="color: #0086BA">FUTSAL</h2>
			<p>Halová obdoba fotbalu, kde hraje méně hráčů na menší ploše, což vyžaduje určitý
			stupeň obratnosti a vytrvalosti. Cílem kroužku je naučit děti nejenom základům futsalu,
			ale i píli, soustředění a spolupráci s ostatními dětmi. Děti v kroužku trénují soustředění, správný pohyb,
			techniku a zlepší si celkovou fyzickou kondici. Jako bonus jsme si pro děti v našem
			kroužku připravili jedinečnou příležitost ve formě <strong>exkurze na fotbalový stadion SK
			Slavia Praha</strong>, kde navštíví muzeum Slavie a <strong>setkají se s prvoligovými fotbalisty</strong>.</p>
		`
	},
	'micove-a-pohybove-hry': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/micove-a-pohybove-hry.png" alt="" /></p>
			<h2 style="color: #0086BA">MÍČOVÉ A POHYBOVÉ HRY</h2>
			<p>Ideální kroužek pro všechny děti, které nechtějí zůstat
			pouze u jednoho sportu. Pro děti v našem kroužku jsme si
			připravili pestrý mix míčových kolektivních sportů, jako je házená, volejbal,
			basketbal, badminton, nohejbal, fotbal a florbal, jejichž základy si děti osvojí,
			naučí se spolupráci s ostatními, soustředění, správnému pohybu, technice
			a zlepší si celkovou fyzickou kondici a pohybové schopnosti.</p>
		`
	},
	'florbal': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/florbal.png" alt="" /></p>
			<h2 style="color: #0086BA">FLORBAL</h2>
			<p>Nejoblíbenější a nejrychleji se rozvíjející sport na školách. Cílem kroužku je
			naučit děti ovládat florbalovou hůl a míček a spolupracovat v kolektivu. Jako
			bonus jsme si pro děti připravili <strong>uspořádání florbalového turnaje pod záštitou
			FbŠ Bohemians Praha za účasti bývalého reprezentanta
			a trenéra Michala Jedličky</strong>.</p>
		`
	},
	'joga-pro-deti': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/joga-pro-deti.png" alt="" /></p>
			<h2 style="color: #0086BA">JÓGA PRO DĚTI</h2>
			<p>Ideální pohyb pro všechny děti, který pomáhá aktivní
			jedince zklidnit. Děti se naučí vnímat své tělo a svůj vnitřní
			svět, učí se koncentrovat a rozvíjet emoční inteligenci. Cvičení
			jógy zlepšuje zdraví a vede ke zdravému životnímu stylu, zvyšuje fyzickou
			zdatnost, fl exibilitu, sílu a rovnováhu těla. Děti se naučí správné koordinaci
			těla a soustředěnosti, učí se správnému dýchání, kooperaci s ostatními dětmi
			a nesoutěživosti. U tišších dětí pomáhá jóga budovat zdravé sebevědomí
			a dává jim jedinečný pohybový základ do života.</p>
		`
	},
	'aerobik': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/aerobik.png" alt="" /></p>
			<h2 style="color: #0086BA">AEROBIK</h2>
			<p>Kondiční cvičení vhodné pro všechny děti, které mají rády pohyb a hudbu. Děti se
			naučí základní kroky a prvky aerobiku, jenž tvoří základ jednoduchých i složitějších sestav,
			a choreografií doprovázených moderní rytmickou hudbou. Dále si osvojí správné držení těla, protáhnou si
			celé tělo a posílí svou vytrvalost, flexibilitu a fyzickou zdatnost.</p>
		`
	},
	'parkour': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/parkour.png" alt="" /></p>
			<h2 style="color: #0086BA">PARKOUR</h2>
			<p>Svoboda a umění pohybu, jehož základem je schopnost dostat se z bodu A do bodu B efektivně za
			použití vlastního těla, a to prostřednictvím akrobatických prvků, salt neboli flipů, přeskoků a jiných
			parkourových technik. Děti se učí překonávat libovolné překážky v přirozeném a bezpečném prostředí,
			naučí se základní a pokročilá salta, naučí se znát své tělo a zlepší si celkovou fyzickou kondici.</p>
		`
	},
	'bojove-sporty': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/bojove-sporty.png" alt="" /></p>
			<h2 style="color: #0086BA">BOJOVÉ SPORTY A SEBEOBRANA</h2>
			<p>Náplň kroužku je postavena na pestrém mixu asijských bojových umění, jako je například tchaj-ťi, aikido,
			džiu-džitsu, kung-fu, krav maga, judo a karate, které si budou moci děti v kroužku vyzkoušet a osvojit si
			jejich základy. Naučí se základním úderům a kopům, jak se bránit, zlepší si svou koncentraci a sebekontrolu
			a naučí se respektu. Zlepší si celkovou kondici a naučí se i morálním hodnotám bojovníka.</p>
		`
	},
	'zs-atletika': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-atletika.png" alt="" /></p>
			<h2 style="color: #0086BA">ATLETIKA</h2>
			<p>Atletický kroužek učí děti hravou formou základy atletických disciplín
			(běh, hod, skok, základy gymnastiky) a děti se seznamují se základními
			technikami. Zvyšují si svoji fyzickou zdatnost, flexibilitu, vytrvalost,
			soustředění a zlepšují si celkovou fyzickou kondici a pohybové
			schopnosti.
			Cílem kroužku je v dětech vybudovat celoživotní lásku k pohybu a
			seznámit je s pestrým mixem atletických cvičení.</p>
		`
	},
	'zs-badminton': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/badminton.png" alt="" /></p>
			<h2 style="color: #0086BA">BADMINTON</h2>
			<p>Rychlost, vytrvalost, pestrost, ale také zábava, to je badminton. Na kroužku badmintonu se
			děti v prvních lekcích seznamují s pravidly tohoto sportu, učí se správné držení rakety,
			techniky základních úderů, podání a pohyb na hřišti. Následně se budou děti v těchto
			oblastech nadále zdokonalovat. Naučí se, jak držet raketu forhendovým a bekhendovým
			způsobem. Děti se v kroužku učí také férovému a sportovnímu chování. Kroužek je určený
			pro všechny děti, které se chtějí naučit hrát badminton na takové úrovni, aby si tento sport
			mohly naplno užít a především, měly radost ze hry se svými kamarády.</p>
		`
	},
	'zs-padel-tenis': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/padel-tenis.png" alt="" /></p>
			<h2 style="color: #0086BA">PADEL TENIS</h2>
			<p>Padel tenis je sport nejvíce podobný tenisu a squashi. Hraje se obvykle ve dvojicích nebo
			čtveřicích, kdy týmy jsou od sebe odděleny sití na hřišti přibližně o rozloze třetiny tenisového
			kurtu. Hrací plocha je lemována zdmi, které hráči využívají při hře. Děti se v prvních lekcích
			seznamují s pravidly tohoto sportu, učí se správné držení rakety, techniky základních úderů,
			podání a pohyb na hřišti. Tento nový sport vzrostl nyní velmi na oblibě, děti na kroužku zažijí
			spoustu zábavy a především se naučí rychlosti, soustředění, vytrvalosti a týmové spolupráci.</p>
		`
	},
	// -------------------------------

	// zs tanecni
	'scenicky-tanec': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/scenicky-tanec.png" alt="" /></p>
			<h2 style="color: #F2915C">SCÉNICKÝ TANEC</h2>
			<p>Moderní výrazový tanec, jehož cílem je scénické vyjádření příběhu, myšlenky
			nebo pocitu. Děti skrz pohyb ztvárňují určitý děj a náladu, přičemž kombinují
			řadu různých technik. V našich hodinách se děti naučí rytmice, budou
			rozvíjet pohybovou paměť a koordinační schopnosti. Naučí se pracovat
			s dynamikou, rytmem, tempem a prostorem. Technická cvičení budeme
			prokládat improvizacemi. Tento tanec se stane pro děti zábavnou možností,
			jak vyjádřit sebe sama a zároveň pochopit okolní svět.</p>
		`
	},
	'contemporary-dance': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/contemporary-dance.png" alt="" /></p>
			<h2 style="color: #F2915C">CONTEMPORARY DANCE</h2>
			<p>Moderní současný tanec, který kombinuje různé
			taneční techniky a styly, jako je moderní tanec,
			floorwork, klasický tanec s prvky baletu, improvizaci
			a klade důraz na individuální dramatický a pohybový projev
			dítěte. Tento taneční styl pracuje se změnami v rytmu, rychlosti, směru
			a vychází z přirozených pohybů těla. Děti vedeme ke správnému držení
			těla, osvojí si smysl pro rytmus, nechávají se vést hudbou a do tance vloží
			kus sebe.</p>
		`
	},
	'zs-street-dance': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-street-dance.png" alt="" /></p>
			<h2 style="color: #F2915C">STREET DANCE</h2>
			<p>Děti se seznámí se všemi volnými styly pouličních tanců, jako je hip hop,
			Just Dance, X-Dance, RnB, Modern Street, Jazz-Latin Street, breakdance
			a reggaeton, které jsou mezi dětmi čím dál populárnější. Tyto tance mají
			společný životní styl, patří mezi moderní formy pohybu a jsou pro děti
			vynikajícím odreagováním. Důraz je kladen na rozvoj tanečního projevu,
			celkovou tělesnou flexibilitu a kondici. Děti si při tanci utvářejí svoji vlastní
			image a zažijí jedinečný pocit uvolnění.</p>
		`
	},
	'zs-zumba': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-zumba.png" alt="" /></p>
			<h2 style="color: #F2915C">ZUMBA</h2>
			<p>Taneční fitness program pro děti, který pracuje s přirozenou dětskou energií. Děti se
			naučí taneční fitness choreografie v rytmu latinskoamerické hudby. Nebudou chybět prvky stylu hip hop
			a nejrůznější taneční a krokové variace. Děti se zdokonalí ve flexibilitě, obratnosti, koordinaci a naučí se
			týmové spolupráci. Tancování zumby je velmi dynamické, děti nadchne svou typickou
			atmosférou. Dnes patří k moderním a velmi populárním tanečním cvičením.</p>
		`
	},
	'roztleskavacky': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/roztleskavacky.png" alt="" /></p>
			<h2 style="color: #F2915C">ROZTLESKÁVAČKY</h2>
			<p>Děti se seznámí se sportovním odvětvím roztleskávaček, naučí se základní
			pozice, cviky a skupinové choreografie obsahující zejména prvky tance, které
			jsou ovlivněné tanečními styly disco dance, show dance, street dance a také
			baletními prvky. Děti nacvičují své choreografie s pompony, seznámí se i se základními prvky
			gymnastiky, aerobiku a akrobacie. Osvojí si základní návyky správného držení těla, rozvinou přirozenou
			ohebnost a posílí si vytrvalost a fyzickou zdatnost.</p>
		`
	},
	'hula-hoop': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/hula-hoop.png" alt="" /></p>
			<h2 style="color: #F2915C">HULA HOOP</h2>
			<p>Zábavná taneční forma cvičení, kde děti tančí s obručí
			nejen kolem pasu, ale zapojí i ostatní části těla: krk,
			ruce, boky a nohy. Naučí se spoustu tanečních kreací a triků. Jedná se
			o dynamické cvičení, taneční a artistickou disciplínu, ve které děti rozvinou
			svou taneční schopnost, pohybové nadání, koordinaci, smysl pro rytmus
			a zpevní si celé tělo.</p>
		`
	},
	'modern-dance': {
		color: 'ms-orange',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/modern-dance.png" alt="" /></p>
			<h2 style="color: #F2915C">Modern dance</h2>
			<p>Jedinečný kroužek moderních současných tanců, kde se prolínají různé taneční styly jako jsou MVT
			dance, Contemporary dance, Street dance a Street Jazz. Děti si vyzkoušejí různé taneční techniky,
			naučí se mnoho krokových a tanečních variací a zdokonalí si obratnost, koordinaci a pružnost těla.
			Děti si při tanci utvářejí svoji vlastní image, zažijí jedinečný pocit uvolnění a důraz je kladen na rozvoj
			tanečního projevu.</p>
		`
	},

	//-------------------------------

	// zs vytvarne
	'zs-tvorive-dilny': {
		color: 'yellow',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-tvorive-dilny.png" alt="" /></p>
			<h2 style="color: #FCEA0D">TVOŘIVÉ DÍLNY A VÝROBA ŠPERKŮ</h2>
			<p>Krásný kroužek, který je určen nejen pro děvčata, ale i pro chlapce, jež láká kreativní
			umělecká činnost. Tento kroužek je zaměřen jak na výrobu šperků, tak i na netradiční
			výtvarné techniky, kde se děti naučí kreativně pracovat s nejrůznějšími materiály.
			Ruční výroba šperků je autorská a originální, děti si mohou vyrobit krásný dárek
			pro své blízké, který určitě potěší i rodiče. Děti si vytvoří náhrdelníky, náušnice,
			prstýnky, náramky, ale třeba také přívěsky ke klíčům a v kroužku nebudou
			chybět ani netradiční výtvarné techniky, jako je decoupage, drátkování, tvoření
			z organzy, patchwork, kanzaši, pletení z pedigu, bavlnkování, malba na kameny,
			textil a mnoho jiných.</p>
		`
	},
	'zs-keramika': {
		color: 'yellow',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-keramika.png" alt="" /></p>
			<h2 style="color: #FCEA0D">KERAMIKA</h2>
			<p>Velmi oblíbený kroužek, kde se děti naučí pracovat s keramickou hlínou, seznámí
			se s modelářskými technikami, naučí se dekorovat keramiku glazurami,
			engobami, akrylovými barvami a dekorovat pomocí skla. Děti si budou rozvíjet
			jemnou motoriku a manuální zručnost. V tomto kroužku se uvolní, přirozenou
			formou zapojí svou fantazii, kreativní myšlení, rozvinou estetické cítění a tvůrčí
			dovednosti. Domů si odnesou spoustu krásných výrobků.</p>
		`
	},
	'netradicni-vytvarne-dilny': {
		color: 'yellow',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/netradicni-vytvarne-dilny.png" alt="" /></p>
			<h2 style="color: #FCEA0D">NETRADIČNÍ VÝTVARNÉ DÍLNY</h2>
			<p>Výtvarně obohacující, tvořivý a inspirativní kroužek pro děti, v němž nebudou chybět nadstandardní
			výtvarné techniky. Je určen všem dětem, které se chtějí naučit novým, kreativním výtvarným technikám
			a práci s netradičními materiály. Děti si vytvoří krásné a originální výrobky, které si odnesou domů. Seznámí
			se například s technikami decoupage, quilling, drátkování, kašírování, bubble -up či špachtličkování. Budou
			malovat na hedvábí a porcelán, vyzkoušejí si práci s polymerovou FIMO hmotou, plastickou a prostorovou
			tvorbu, udělají si sádrové odlitky, budou malovat na vitráž, vytvoří si voňavá mýdla, květinové šperky
			a mnoho dalších krásných výrobků. Tento kroužek rozvíjí smyslové vnímání a cit pro estetičnost.</p>
		`
	},
	'malovani-a-kresleni': {
		color: 'yellow',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/malovani-a-kresleni.png" alt="" /></p>
			<h2 style="color: #FCEA0D">MALOVÁNÍ A KRESLENÍ</h2>
			<p>Náplň kroužku je zaměřena na základy kresby a malby podle skutečnosti a volné
			tvorby, která vychází z vlastní představivosti. Děti seznámíme se základními
			výtvarnými technikami a tématy. Děti se učí poznávat základy stavby obrazu,
			kompozici, barevnou skladbu, budou se učit kresbu zátiší (tužkou, uhlem,
			rudkou, tuší) a postupně přejdou k malbě (pastel, akvarel, akryl a olej). Budou
			se učit kresbu architektonických objektů, kde je kladen důraz na perspektivu
			a proporce, a figurální a portrétní kresbu. Kromě praktických dovedností jsou
			děti seznamovány i s teoretickými principy výtvarných technik. Tento kroužek
			rozvíjí umělecké nadání, estetické cítění a kreativitu.</p>
		`
	},
	'komiks': {
		color: 'yellow',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/komiks.png" alt="" /></p>
			<h2 style="color: #FCEA0D">ZÁKLADY KOMIKSU A ILUSTRACE PRO DĚTI</h2>
			<p>Tento krásný kroužek má za cíl hravou a kreativní formou obeznámit děti s komiksem
			a ilustrací, poukázat na nové trendy a předvést, co mají komiks a ilustrace společného
			a v čem se liší. Děti se naučí navrhnout vlastní komiksovou postavu a rozpracovat její
			příběh. Obeznámí se s různými vizuálními možnostmi komiksu, zjistí, jak pracovat s prostorem a časem
			v komiksu, v ilustraci, ale také v animaci, a naučí se uvést své myšlenky, nápady a příběhy do vizuální
			podoby. V závěru kurzu si děti vyzkoušejí tvorbu vlastní animace. Děti se na kurzu seznámí s českými
			a světovými komiksovými autory a ilustrátory. Čeká je také seznámení s širší škálou technik tvorby
			komiksových příběhů (kresba tužkou, perem, pastelkami, malba barvami ředitelnými vodou, vystřihování),
			povíme si, jak postupovat při vytváření komiksového deníku a čeho se vyvarovat při práci s písmem. Tento
			kroužek pomůže našim malým autorům lépe komunikovat prostřednictvím obrazů, vyjádřit
			své myšlenky, příběhy a převést je do vizuální podoby.</p>
		`
	},
	// ------------------------------

	// zs naucne
	'kulinarske-vareni': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/kulinarske-vareni.png" alt="" /></p>
			<h2 style="color: #2e2a4d">KULINÁŘSKÉ VAŘENÍ A PEČENÍ</h2>
			<p>Velmi populární kroužek mezi dětmi, které rády kuchtí a pečou a jsou
			inspirovány pořady jako MasterChef Česko a Peče celá země. V kroužku
			kulinářského vaření a pečení jsme si pro děti připravili spoustu zajímavých,
			originálních receptů a barvitých vůní. Děti budou připravovat nejrůznější jídla
			a péct dobroty inspirované světovou kuchyní. Zavítáme do kuchyní Francie,
			Itálie, Španělska, Maďarska, Anglie, Belgie, Slovenska, Německa, Řecka,
			Japonska, Indie, Thajska a samozřejmě nebude chybět ani česká kuchyně.
			Děti budou nadšené rozmanitostí typů jídel a pečených dobrot. Vaření s námi
			se pro děti stane opravdovým kulinářským zážitkem. Výuku kulinářského
			vaření a pečení nabízíme dětem i v anglickém jazyce, aby se zábavnou formou
			zdokonalily i v angličtině.</p>
		`
	},
	'zs-maly-astronom': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-maly-astronom.png" alt="" /></p>
			<h2 style="color: #2e2a4d">MALÝ ASTRONOM</h2>
			<p>Kroužek je vhodný pro všechny zvídavé děti, které se s nadšením zajímají o vesmír,
			Zemi, Slunce, Měsíc a sluneční soustavu. Chtějí objevovat krásy a tajemství
			nekonečného vesmíru, dozvědět se, jak vesmír vznikl, zajímavosti o hvězdách,
			planetách i atomech. Zábavnou formou děti seznámíme se základními
			znalostmi astronomie, řekneme jim o různých výzkumech v kosmu, naučíme
			je poznávat hvězdy, souhvězdí i jiná pozorovatelná tělesa a jevy, jako jsou
			komety, meteory, planetky, mlhoviny či galaxie. V tomto kroužku se děti
			dozvědí nejen spoustu zajímavých informací, ale budou také
			vyrábět, sestrojíme si létající raketu, periskop, vytvoříme si
			svoji mlhovinu a mnoho dalšího. Tento kroužek se stane
			pro děti nezapomenutelnou cestou za hranice zemské
			atmosféry.</p>
		`
	},
	'sileny-vedator': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/sileny-vedator.png" alt="" /></p>
			<h2 style="color: #2e2a4d">ŠÍLENÝ VĚDÁTOR A BADATEL</h2>
			<p>Náš vědecký kroužek přináší dětem plno zajímavých a netradičních pokusů,
			které jim budou rozvíjet logický úsudek, fantazii, všeobecný přehled a zároveň
			naplní jejich zvídavost. Cílem kroužku je děti seznámit s přírodovědnými,
			fyzikálními a chemickými pokusy, s dětmi
			budeme bezpečně experimentovat
			a bádat. Seznámíme je s principy
			přírodních zákonů, děti si
			například vyzkoušejí, jak z bonbonu
			a sodovky vyrobit gejzír, nebo pokus
			s neviditelným písmem, dále pokusy
			s octem a sodou, vytvoříme si duhu,
			svou fosilii, magnetický sliz nebo sníh,
			podmořskou sopku, ukážeme si
			princip chromatografie, řekneme si
			o magnetismu, o atmosférickém
			tlaku a mnoho dalšího. Děti si
			vyzkoušejí, jaké to je, stát se
			malým vědcem, a ukážeme jim,
			že věda je zábava.</p>
		`
	},
	'skaut-junior': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/skaut-junior.png" alt="" /></p>
			<h2 style="color: #2e2a4d">MALÝ PŘÍRODOVĚDEC A SKAUT JUNIOR</h2>
			<p>Zahrnuje vás vaše dítko všetečnými otázkami, jak příroda funguje? Milují Vaše děti přírodu? Dokáže hodiny 
			zkoumat broučky, zvířátka a květiny? Tak přesně pro vaše zvídavé děti je náš kroužek Malý přírodovědec a skaut. 
			Kroužek je určen všem dětem, které rády jezdí na tábory, tráví čas v přírodě a chtěly by se na toto téma dozvědět více. 
			Naučí se spoustu skautských praktických, kreativních a rukodělných dovedností v oblasti rybaření, tábornictví, 
			modelování, vědy, vesmíru, ekologie, první pomoci, dopravních značek a dalších krásných témat. Vysvětlíme si, 
			jak celá naše příroda funguje, co se v ní všechno nachází a jak se k ní a v ní máme chovat. Tento krásný kroužek 
			s tématy o rostlinách, houbách, živočichách žíjící na louce, v lese i v řece potěší srdce každého přírodovědce 
			a skauta. Poslechneme si v nahrávkách život v lese, vysvětlíme si, jak a kde žijí někteří živočichové. 
			Budeme podrobněji zkoumat živočichy, houby, byliny, stromy, rozeznávat zpěv ptactva i stopy divé zvěře.
			Tento kroužek je pro děti velmi přínosný, jelikož není jednostranně zaměřený. Pomáhá dětem upevňovat vztahy 
			v kolektivu, učí je samostatnosti a toleranci ke svému okolí. Děti si vyzkoušejí široké spektrum činností, 
			které uplatní ve svém každodenním životě.</p>
		`
	},
	'kutil': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/kutil.png" alt="" /></p>
			<h2 style="color: #2e2a4d">KUTIL A MODELÁŘ</h2>
			<p>Krásný kroužek pro všechny děti, jež rády něco kutí, vyrábějí
			a modelují a chtěly by se zdokonalit v manuální zručnosti
			a vyrobit si spoustu krásných věcí, které si pak
			odnesou i domů. Děti se naučí rozeznávat materiály
			a jejich vlastnosti, poznávají jednotlivé druhy
			nářadí a učí se s nimi pracovat. Děti budou vyrábět
			z rozmanitých materiálů a vyrobí si například budku
			pro ptáky, stojánek na tužky, rámeček na fotku, meč
			ze dřeva, letadlo, vesmírnou loď, truhlík na bylinky,
			hodiny, upletou si košík, vyrobí si látkovou tašku a mnoho
			dalšího. Tento kroužek vede děti nejen k manuální zručnosti, ale i k trpělivosti,
			pečlivosti a přesnosti.</p>
		`
	},
	'pocitacovy-krouzek': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/pocitacovy-krouzek.png" alt="" /></p>
			<h2 style="color: #2e2a4d">POČÍTAČOVÝ KROUŽEK, PROGRAMOVÁNÍ A TVORBA YOUTUBE VIDEÍ</h2>
			<p>Kroužek je určen všem dětem, které by se chtěly
			zdokonalit a upevnit si své znalosti a dovednosti
			v oblasti IT. Děti se budou věnovat základním
			poznatkům o počítači, například co je
			to hardware nebo software, a zkusí si
			práci v jednoduchých programech
			Microsoft Office. Budou se také
			věnovat programům na stříhání videí
			v rámci kanálu YouTube, seznámí
			se se základy jednoduchého
			programování a představíme jim
			základní programovací jazyk Scratch.
			Obeznámíme je rovněž s riziky
			sociálních sítí, na co si dát pozor při
			online komunikaci, a seznámíme
			je s pravidly zabezpečení počítače.
			Hlavním cílem kroužku je seznámit
			děti se základy IT technik a ukázat jim
			jiné než herní využití počítače.</p>
		`
	},
	'harry-potter-vitejte-v-bradavicich': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/harry-potter-vitejte-v-bradavicich.png" alt="" /></p>
			<h2 style="color: #2e2a4d">Harry Potter, vítejte v Bradavické škole!</h2>
			<p>Krásný kroužek pro všechny děti, které milují Harryho Pottera a chtějí zažít dobrodružství a spoustu
			legrace. Kroužek plný čar, kouzel a zábavných aktivit, na které děti jen tak nezapomenou. Děti budou
			zařazeny do jednotlivých kolejí podle moudrého klobouku. Na děti čekají hodiny tajných pokusů,
			bylinkářství, kouzelných lektvarů, boudou se učit poznávat magická zvířata, číst ve starodávných
			runách a nebude chybět ani netradiční forma famfrpálu. Vyrobíme si kouzelnický plášť, klobouk,
			hůlku a budeme kouzlit. Děti, očekávejte zvací dopis z Bradavic.</p>
		`
	},
	'zs-modni-tvorba': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-modni-tvorba.png" alt="" /></p>
			<h2 style="color: #2e2a4d">Módní tvorba</h2>
			<p>V kroužku Módní tvorby si děti osvojí základy práce ručního šití. Budeme navrhovat a šít krásná vlastní 
			díla z různých typů látek a naučíme se dekorovat různorodým materiálem. Představíme si různé látky, textury, 
			vzory a probereme současné módní trendy. Vysvětlíme si, jak se správně dělá střih a vybereme k němu správný 
			materiál.  Děti budou kombinovat různé materiály, barvy a vyzkoušíme si možnosti barvení textilu. Děti 
			vykročí do kreativního světa, kde se jim bude zlepšovat jemná motorika, soustředění, získají nový úhel 
			pohledu a dají průchod své fantazii. Děti si vytvoří vlastní módní kolekci, jako praví návrháři.</p>
		`
	},
	'lego-roboti': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/lego-roboti.png" alt="" /></p>
			<h2 style="color: #2e2a4d">Lego roboti</h2>
			<p>Stavebnice s technologií <strong>LEGO</strong>®WeDo® a <strong>LEGO</strong>®MINDSTORMS® EV3 je svět vzdělávacích robotů a na tomto krásném 
			kroužku děti tvoří, staví a programují s Lego stavebnicí, pracují na dovednostech v oblasti vědy, techniky, 
			matematiky, fyziky a naučí se základům programování. Před každou lekcí lektor dětem představí, jakému tématu 
			se budou na lekci věnovat a jakého robota si na dané lekci sestaví a naprogramují. Lektor dětem pustí krátkou 
			video nahrávku pro větší představivost dětí a rozebere s dětmi vědomostně dané téma. Děti si sestaví svého robota 
			pomocí digitálního návodu, kterého si s lektorem společně naprogramují a budou sledovat, jak jim robot funguje 
			v praxi. Po naprogramování budou probíhat soutěže mezi všemi roboty. Tato forma vzdělávání je pro děti interaktivní, 
			zábavná a umožňuje dětem rozvíjet dovednosti v týmové práci, kreativitě, řešení problémů a logickém myšlení.</p>
		`
	},
	'zs-poznavame-cesko-a-evropu': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/poznavame-cesko-a-evropu.png" alt="" /></p>
			<h2 style="color: #2e2a4d">Poznáváme Česko a Evropu</h2>
			<p>Poznávat naši vlast a Evropu nemusíme jen při cestování. Zkuste to společně s našim
			zábavným a vědomostním kroužkem Poznáváme Česko a Evropu. Děti se na kroužku
			dozvědí celou řadu zajímavostí o naší vlasti a také o Evropě. Zábavnou a hravou formou
			budou seznámeny s kulturami, jídlem, vlajkami států, historií, rostlinami, zvířaty, nebudou
			chybět ani nejvýznamnější památky a tradice. Na tomto kroužku se dětí dozvědí celou řadu
			zajímavostí a užijí si spoustu zábavy se svými kamarády.</p>
		`
	},
	'zs-deskove-hry': {
		color: 'blue',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/deskove-hry.png" alt="" /></p>
			<h2 style="color: #2e2a4d">Deskové hry</h2>
			<p>Tento kroužek je určený pro děti, které baví společenské hry a chtějí kouzelný svět her dále
			prozkoumávat a objevovat. V našem kroužku si děti v průběhu roku zahrají mnoho různých
			her, od těch klasických, až po nové nevšední deskové hry. Chybět nebudou ani turnaje mezi
			dětmi. Kromě skvělé zábavy je cílem kroužku u dětí rozvíjet logické myšlení, paměť,
			soutěživost, komunikaci, spolupráci a soustředění.</p>
		`
	},

	// -------------------------------

	// zs hudebni
	'hra-na-zobcovou-fletnu': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/hra-na-zobcovou-fletnu.png" alt="" /></p>
			<h2 style="color: #5AAF47">HRA NA ZOBCOVOU FLÉTNU</h2>
			<p>Zobcová flétna je jedním z nejsnáze zvládnutelných hudebních nástrojů a tento
			kroužek je ideální pro všechny děti, které by si rády vyzkoušely hru na nějaký
			hudební nástroj. Cílem kroužku je vtáhnout děti do hudebního světa, naučit je
			poznávat a číst noty, rozvíjet jejich hudební, rytmické i harmonické cítění. Děti
			získají kvalitní základy hudební nauky, naučí se správnému držení hudebního
			nástroje a velkým přínosem hry na flétnu je i upevňování správného dýchání.
			Na děti čeká spousta hezkých písniček i doprovodných rytmických cvičení.
			Děti od nás obdrží náš notový materiál.</p>
		`
	},
	'hra-na-kytaru': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/hra-na-kytaru.png" alt="" /></p>
			<h2 style="color: #5AAF47">HRA NA KYTARU</h2>
			<p>V našem kroužku děti získají potřebné dovednosti ve hře na tento
			hudební nástroj. Naučí se základní akordy a rytmy, transponování,
			tedy převedení písně do vyhovující tóniny, dále hraní ve skupině,
			tedy různé polohy pro různé akordy, a doprovody písní a po
			prvním roce již doprovodí jednoduché písničky. Naučí se hrát
			z not, z tabulatur, ale také podle sluchu a zahrají si lidové i moderní
			písničky.</p>
		`
	},
	'hrajeme-a-zpivame-s-djembe': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/hrajeme-a-zpivame-s-djembe.png" alt="" /></p>
			<h2 style="color: #5AAF47">HRAJEME A ZPÍVÁME S DJEMBE</h2>
			<p>Kroužek je vhodný pro všechny děti, které mají zájem o zpěv a hudbu. Zábavnou
			formou se zde naučí základním technikám zpěvu a správnému dýchání. Děti poznají
			rozdíly mezi sólovým a sborovým zpěvem a zjistí, jaký má jejich hlas rozsah. Na děti čeká pestrý hudební
			repertoár, v němž nebudou chybět lidové ani moderní písničky či písně z filmů a muzikálů. Zpěv bude
			doprovázen hudebními nástroji, například africkými bubny djembe, u kterých se
			děti krásně uvolní. Hra na djembe harmonizuje mysl a dodá dětem velkou
			dávku pozitivní energie.</p>
		`
	},
	'zs-ukulele': {
		color: 'ms-green',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/ukulele.png" alt="" /></p>
			<h2 style="color: #5AAF47">Ukulele</h2>
			<p>Kroužek Ukulele pro mladší začátečníky je určen pro úplné začátečníky, kteří nemají žádné
			zkušenosti se hrou na ukulele. Kroužek se vyučuje podle zpěvníku.
			Na lekcích se naučíme, jak ukulele držet, naladit, jak zahrát základními akordy a rytmy
			jednoduché lidové písně, které budeme doprovázet i zpěvem. Výhodou ukulele bezesporu je,
			že je malé, cenově dostupné, má „jen 4 struny“ a tak se na něj děti učí poměrně rychle, což
			pro ně funguje jako skvělá motivace. Ukulele je nejen ideálním drnkacím nástrojem pro
			začínající malé hráče, kterým se rozvíjí motorika a fyziologie, ale také může být perfektním
			odrazovým můstkem pro kytaru. Výuka hudebního nástroje se také neobejde bez domácí
			přípravy.</p>
		`
	},
	// ------------------------------

	// zs divadelni
	'zs-loutkove': {
		color: 'purple',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-loutkove.png" alt="" /></p>
			<h2 style="color: #623F90">LOUTKOVÉ A MAŇÁSKOVÉ DIVADLO</h2>
			<p>Pro děti jsme si připravili krásný kroužek, kde budou moci rozvíjet své
			komunikační, tvůrčí a pohybové dovednosti. Dostanou možnost rozvinout
			svoje kreativní myšlení a fantazii, ponoří se do světa nejznámějších českých
			pohádek a zahrají si krásná představení s loutkami a maňásky. Pomocí
			různorodých divadelních her si vyzkoušejí mnoho zajímavých rolí, naučí
			se pracovat s hlasem, gesty a mimikou. Děti se přirozenou formou učí
			překonávat trému a spolupracovat v kolektivu dětí.</p>
		`
	},
	'zs-hudebne-dramaticky-krouzek': {
		color: 'purple',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-hudebne-dramaticky-krouzek.png" alt="" /></p>
			<h2 style="color: #623F90">HUDEBNĚ -DRAMATICKÝ KROUŽEK</h2>
			<p>Výuka je koncipována veselou a hravou formou, v kroužku je kladen důraz na
			přátelství, vzájemnou spolupráci a rozvoj dramatického
			cítění. Děti si zahrají spoustu krásných, naučných
			divadelních her a vyzkoušejí si mnoho rolí, naučí se pracovat s hlasem,
			gesty a mimikou. Nebude chybět ani zpěv a populární muzikálová
			představení. Děti se přirozeným způsobem naučí překonávat trému
			a rozvíjet svou fantazii. Výuku hudebně-dramatického kroužku
			nabízíme dětem i v anglickém jazyce, aby si hravou a zábavnou
			formou zdokonalily i svou angličtinu.</p>
		`
	},
	'zs-muzikalove-herectvi-a-tvurci-psani': {
		color: 'purple',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-muzikalove-herectvi-a-tvurci-psani.png" alt="" /></p>
			<h2 style="color: #623F90">MUZIKÁLOVÉ HERECTVÍ A TVŮRČÍ PSANÍ</h2>
			<p>Krásný a rozmanitý kroužek pro děti, na kterém se děti učí zábavnou formou základním muzikálovým principům, 
			kterými jsou zpěv, tanec a herectví. Děti si na kroužku osvojí různorodá rytmická, pěvecká, taneční a herecká 
			cvičení a výsledkem budou muzikálová představení, která si s lektorem zahrají a dají průchod své fantazii. 
			Děti si vyzkoušejí psát vlastní scénáře a hravou formou objeví, jak ze slov vytvářet obrazy i příběh a vytvoří 
			si svoje vlastní muzikálové dílo. Děti se budou učit tvorbě básní a seznámí se s principy psaní článku do 
			časopisu. Jako opravdoví spisovatelé si vyzkouší psaní textů různých žánrů, od pohádky, bajky až po fantasy.
			Děti objeví, že tvůrčí psaní je hra a zábava se slovy, ze kterých samy stvoří postavy a pro ně příběhy ve 
			světě vlastní fantazie!</p>
		`
	},
	// ------------------------------

	// zs jazykove
	'zs-logopedie': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-logopedie.png" alt="" /></p>
			<h2 style="color: #CB79AF">ČESKÝ JAZYK<br>S KAMARÁDEM</h2>
			<p>Tento kroužek je vhodný pro všechny děti, které si chtějí či potřebují procvičovat správnou výslovnost 
			a rozvíjet jazykové dovednosti s cílem zlepšit si schopnost formulace a vyjadřování myšlenek. V našem kroužku 
			se mohou děti těšit na různá dechová cvičení, rozvíjení jemné a hrubé motoriky, na oromotorická cvičení, říkanky, 
			básničky, slovní hříčky, písničky, skupinové soutěže a omalovánky rozvíjející grafomotoriku dítěte. Kroužek 
			podporuje přirozený a zdravý rozvoj řeči a komunikační dovednosti českého jazyka. Nenahrazuje terapie klinického logopeda.</p>
		`
	},
	'zs-hrava-anglictina': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-hrava-anglictina.png" alt="" /></p>
			<h2 style="color: #CB79AF">HRAVÁ ANGLIČTINA</h2>
			<p>Kroužek je určen všem dětem, a to i úplným začátečníkům. Naše hodiny jsou dětem připraveny na míru
			s ohledem na jejich věk a dosavadní znalosti. Naši lektoři používají kvalitní, moderní metody výuky, snaží
			se děti motivovat a vzbudit u nich zájem o tento jazyk. Forma výuky bude pro děti zábavná, plná her,
			hudby a tematických aktivit. Klademe velký důraz na konverzaci, aby se děti nebály mluvit
			a postupně přirozenou formou svůj strach z používání jazyka odbouraly. Dbáme také na
			správnou výslovnost. Děti se naučí základní slovní zásobu, kterou budou postupně
			prohlubovat, a nebude chybět ani základní výklad gramatiky. Naším cílem je, aby
			se děti seznámily s jazykem, upevnily si základní znalosti, obohatily si slovní
			zásobu, naučily se správnou výslovnost a používaly jazyk bez ostychu. Naši
			lektoři používají výhradně kvalitní výukové materiály a audiovizuální pomůcky.</p>
		`
	},
	'zs-anglictina-rodily-mluvci': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/zs-anglictina-rodily-mluvci.png" alt="" /></p>
			<h2 style="color: #CB79AF">ANGLIČTINA – ÚROVEŇ RODILÝ MLUVČÍ</h2>
			<p>Kroužek je určen všem dětem, které již mají základní znalost anglického jazyka. Naše hodiny jsou připraveny 
			na míru s ohledem na věk dětí a jejich dosavadní znalosti. Kroužek vyučují výhradně kvalifikovaní lektoři s 
			certifikátem C2 – úroveň rodilý mluvčí. Naši lektoři vyučují jazyk zábavnou a srozumitelnou formou. Velký 
			důraz je kladen na konverzaci, lektor pro děti připravuje tematické aktivity, které se zaměřují zejména na 
			aktivní komunikaci, správnou výslovnost a zároveň rozvíjejí gramatické a poslechové dovednosti dětí. Děti se 
			naučí novým anglickým frázím, vybudují si bohatou slovní zásobu, odbourají ostych mluvit a vybudují si zdravé 
			sebevědomí, které je pro komunikační dovednosti tolik důležité. Naši lektoři používají výhradně kvalitní výukové 
			materiály a audiovizuální pomůcky.</p>
		`
	},
	'francouzsky-jazyk': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/francouzsky-jazyk.png" alt="" /></p>
			<h2 style="color: #CB79AF">FRANCOUZSKÝ JAZYK</h2>
			<p>Krásně znějící románský jazyk. I když je jedním
			z nejtěžších světových jazyků, díky zábavné a hravé
			formě výuky určitě půjde snáz všem dětem, které
			si přejí se francouzsky naučit. Hodiny vedou
			výhradně kvalifikovaní lektoři, s nimiž si děti
			osvojí úplné základy jazyka, naučí se základní
			slovní zásobu, kterou budou postupně
			prohlubovat, zaměří se na správnou
			výslovnost a na komunikační dovednosti
			a seznámí se se základy francouzské
			gramatiky. Naši lektoři používají výhradně
			kvalitní výukové materiály a audiovizuální
			pomůcky.</p>
		`
	},
	'nemecky-jazyk': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/nemecky-jazyk.png" alt="" /></p>
			<h2 style="color: #CB79AF">NĚMECKÝ JAZYK</h2>
			<p>Je určen všem dětem, které si přejí obohatit svoje jazykové
			dovednosti a vedle angličtiny by se rády naučily další světový
			jazyk. Naše hodiny jsou dětem připraveny na míru s ohledem
			na jejich věk i dosavadní znalosti. Naši kvalifikovaní lektoři
			vyučují jazyk zábavnou a srozumitelnou formou. Hodiny jsou
			zaměřeny na výuku všeobecného jazyka, na základní znalost
			gramatiky a na správnou výslovnost. Děti se naučí základní
			slovní zásobu, kterou budou postupně prohlubovat. Lektor
			si pro děti připraví nejrůznější tematické zábavné aktivity.
			Naši lektoři používají výhradně kvalitní výukové materiály
			a audiovizuální pomůcky.</p>
		`
	},
	'cinsky-jazyk': {
		color: 'pink',
		template: `
			<p><img src="https://krouzky-kamarad.cz/img/zs/detail/cinsky-jazyk.png" alt="" /></p>
			<h2 style="color: #CB79AF">ČÍNSKÝ JAZYK</h2>
			<p>V dnešní době světový jazyk, který
			nabývá na popularitě jak u dětí, tak
			u jejich rodičů. Tento jazyk zavede děti
			do jiné kultury, v kroužku se učí základy
			čínského jazyka, jednotlivé čínské znaky,
			čímž si také rozvíjejí jemnou motoriku.
			Každý znak představuje slabiku mluvené
			čínštiny a zároveň má i svůj vlastní význam.
			Kroužek vedou výhradně zkušení lektoři, kteří
			děti naučí správnou výslovnost čínských slabik
			a celých slov, základní fráze, pravidla psaní čínských
			znaků, jednoduchou gramatiku, počítání a zavedou děti
			do tajů této kultury, jejích tradic a zvyků. Není nutná žádná
			předchozí znalost čínštiny. Naši lektoři používají výhradně
			kvalitní výukové materiály a audiovizuální pomůcky.</p>
		`
	},



};
