<template>

	<div v-if="!$route.params.subsection" class="activity__list">
		<div class="activity__list__column" v-for="(column, idx) in this.getActivities()" :key="idx">
			<img :src=column.image.src :alt=column.image.alt />
			<div class="activity__list__column__container">
				<h2 :class="[ 'activity__list__column__title', 'activity__list__column__title--' + column.color ]">{{ column.title }}</h2>
				<div class="activity__list__column__info" v-html="column.template" />

				<router-link v-if="!$route.params.subsection" :to="{ name: 'app', params: { section: 'primestske-tabory-a-tematicke-aktivity', subsection: column.page }}">
					<span :class="['more', 'more--' + column.color]">Více</span>
				</router-link>
			</div>

		</div>
	</div>

	<div v-else-if="this.$route.params.subsection === 'aktivity'" class="activity">
		<div class="activity__item" v-for="(activity, idx) in this.getActivities()" :key="idx">
			<div class="activity__item__image">
				<img :src=activity.image.src :alt=activity.image.alt />
			</div>
			<div class="activity__item__content">
				<h2 :class="['activity__item--' + activity.color]" v-html="activity.title" ></h2>
				<p v-html="activity.content"></p>
			</div>
		</div>
	</div>

	<div v-else class="activity-camp">
		<div class="activity-camp__item" v-for="(activity, idx) in this.getActivities()" :key="idx">
			<div class="activity-camp__item__image">
				<img :src=activity.image.src :alt=activity.image.alt />
			</div>
			<div class="activity-camp__item__content">
				<h2 :class="['activity-camp__item__content__title', 'activity-camp__item__content__title--' + activity.color]" v-html="activity.title" ></h2>

				<p :class="['activity-camp__item__content__perex', 'activity-camp__item__content__perex--' + activity.color]" v-html="activity.perex"></p>

				<p v-html="activity.content"></p>

				<div class="activity-camp__place">
					<strong>Místo konání:</strong> {{ activity.address }}
				</div>
				<div class="activity-camp__time">
					<strong>Čas konání:</strong> {{ activity.time }}
				</div>

				<div class="activity-camp__dates">
					<strong>Tábor se koná v těchto termínech:</strong>
					<p v-html="activity.dates"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { columns, activities, camps } from '../../data/activity';

	export default {
		data: function () {
			return {
				columns: columns,
				activities: activities,
				camps: camps
			};
		},
		methods: {
			getActivities() {
				if (!this.$route.params.subsection) {
					return this.columns;
				}

				if (this.$route.params.subsection === 'aktivity') {
					return this.activities;
				}

				if (this.$route.params.subsection === 'primestske-tabory') {
					return this.camps;
				}
			}
		},
	}
</script>

<style lang="less">

	.last-info {
		padding-left: 20px;
		line-height: 3;
		font-weight: bold;
	}

	.postinfo {
		padding-top: 10px;
		line-height: 1.3;
	}

	.activity-camp {
		width: 670px;
		margin: 0 auto;
		padding-top: 65px;
		line-height: 1.7;

		&__dates {
			p {
				ul {
					text-align: left;
					margin-bottom: 5px;

					li {
						font-size: 20px;
						line-height: 1;

						span {
							font-size: 14px;
							line-height: 1.7;
							position: relative;
							left: -5px;
						}
					}
				}
			}
		}

		&__item {

			padding-top: 55px;

			&__image {
				text-align: center;
				padding-bottom: 20px;
			}

			&__content {

				&__perex {
					font-weight: bold;
					text-align: center;

					&--purple {
						color: #866bad;
					}
					&--red {
						color: #ff7425;
					}
					&--green {
						color: #5bb047;
					}
					&--orange {
						color: #ffdd00;
					}
					&--blue {
						color: #ba408d;
					}
          &--bred {
            color: #e24040;
          }
          &--greenl {
            color: #4CB732;
          }
          &--blue {
            color: #9ACEF1;
          }
          &--orange2 {
            color: #F3672E;
          }
          &--yellow {
            color: #ffdd00;
          }
          &--purple2 {
            color: #872563;
          }
				}

				ul {
					text-align: left;
					margin-bottom: 5px;

					li {
						font-size: 20px;
						line-height: 1;

						span {
							font-size: 14px;
							line-height: 1.7;
							position: relative;
							left: -5px;
						}
					}
				}

				h2&__title {
					font-family: 'Caveat Brush';
					font-size: 30px;
					text-transform: uppercase;
					padding: 0px;
					margin-top: 0px;
					margin-left: 25px;
					text-align: center;

					&--purple {
						color: #866bad;
					}
					&--red {
						color: #ff7425;
					}
					&--green {
						color: #5bb047;
					}
					&--orange {
						color: #ffdd00;
					}
					&--blue {
						color: #ba408d;
					}
          &--bred {
            color: #e24040;
          }
          &--greenl {
            color: #4CB732;
          }
          &--blue {
            color: #9ACEF1;
          }
          &--orange2 {
            color: #F3672E;
          }
          &--yellow {
            color: #ffdd00;
          }
          &--purple2 {
            color: #872563;
          }
				}
			}
		}
	}

	.activity__list {
		width: 900px;
		margin: 0 auto;
		display: flex;
		padding-top: 30px;
		flex-wrap: wrap;
		justify-content: space-between;

		&__column {

			display: flex;
			align-items: flex-start;
			width: 45%;
			padding: 0 0 70px;

			&__info {
				margin-left: 25px;
				padding-right: 10px;
			}

			h2&__title {
				font-family: 'Caveat Brush';
				font-size: 30px;
				text-transform: uppercase;
				padding: 0px;
				margin-top: 0px;
				margin-left: 25px;
				text-align: left;

				&--red {
					color: #ff4040;
				}

				&--green {
					color: #065351;
				}
			}

		}
	}

	.more {
		border-radius: 4px;
		width: 75px;
		display: block;
		color: #fff;
		text-align: center;
		padding: 7px 0;
		margin: 15px 25px 0;

		&--red {
			background: #ff4040 0% 0% no-repeat padding-box;
		}

		&--green {
			background: #065351 0% 0% no-repeat padding-box;
		}
	}

	.activity {
		padding-top: 45px;
		text-align: center;
		width: 500px;
		margin: 0 auto;

		&__item {
			display: flex;

			&__image {
				width: 30%;
				margin-right: 25px;
			}

			h2 {
				font-family: 'Caveat Brush';
				font-size: 30px;
				text-align: left;
			}

			p {
				text-align: left;
			}

			&--blue {
				color: #00589B
			}

			&--purple {
				color: #3D1F6E
			}

			&--blue-light {
				color: #2DA9E1;
			}

			&--green-light {
				color: #5AAF47;
			}

			&--green {
				color: #005351;
			}

			&--orange {
				color: #F49C35;
			}

			&--yellow {
				color: #FFCB00;
			}

			&--pink {
				color: #B9378A;
			}
      &-greenl {
        color: #5AAF47;
      }

		}
	}

	@media screen and (max-width: 500px) {
		.activity {
			width: 100%;

			&__item {
				flex-direction: column;

				h2 {
					text-align: center;
				}

				&__image {
					width: 100%;
				}

				p {
					padding: 0 20px;
				}
			}
		}
	}
</style>
