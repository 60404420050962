<template>
	<div class="custom-select" :tabindex="tabindex" @blur="open = false">
		<div class="arrow" :class="{open: open}" @click="open = !open"></div>
		<div class="selected" :class="{open: open}" @click="open = !open">{{ selected.value }}</div>
		<div class="items" :class="{selectHide: !open}">
		<div
			class="item"
			v-for="{key, value} of options"
			:key="key"
			@click="selected={key, value}; open=false; $emit('input', key)"
		>{{ value }}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		options: { type: Array, required: true },
		tabindex: { type: Number, required: false, default: 0 },
		value: {},
	},
	data() {
		return {
			selected: this.getSelected(),
			open: false
		};
	},
	methods: {
		setSelected(value) {
			this.selected = value;
		},
		getSelected() {
			const emptyValue = { value: null };

			if (this.value && this.options.length > 0) {
				const selected = this.options.find(item => item.key === this.value) || emptyValue;
				return selected;
			}

			if (this.options.length > 0) {
				return this.options[0];
			}

			return emptyValue;
		}
	},
	mounted() {
		this.$emit('input', this.selected.key);
	}
};
</script>

<style scoped>

.custom-select {
	border-radius: 3px;
	position: relative;
	display: flex;
	height: 30px;
	width: 90%;
}
.arrow {
	background: #4DBAE7;
	border-radius: 3px 0px 0px 3px;
	flex: 0 0 30px;
}

.arrow::before {
	position: absolute;
	content: "";
	top: 13px;
	left: 10px;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-color: #fff transparent transparent transparent;
}
.selected {
	background-color: #fff;
	border: 0.25px solid #999999;
	color: #999999;
	cursor: pointer;
	user-select: none;
	padding: 3px 0px 3px 15px;
	flex: 1;
}

.selected.open {
	border: 0.5px solid #999999;
	border-radius: 0px;
	z-index: 3;
}

.items {
	color: #999999;
	border-radius: 0px 0px 3px 3px;
	overflow: hidden;
	border-right: 0.5px solid #999999;
	border-left: 0.5px solid #999999;
	border-bottom: 0.5px solid #999999;
	position: absolute;
	background-color: #fff;
	left: -1px;
	right: 0;
	top: 30px;
	width: 100%;
	z-index: 3;
}

.item {
	color: #999999;
	padding: 10px 0px 10px 8px;
	cursor: pointer;
	user-select: none;
}

.item:hover {
	background-color: #eee;
}

.selectHide {
	display: none;
}
</style>