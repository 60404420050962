export const columns = [
	{
		title: 'PŘÍMĚSTSKÉ TÁBORY',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/tabory.png',
			alt: ''
		},
		color: 'red',
		page: 'primestske-tabory',
		template: `Zábavné a naučné příměstské tábory pro děti od 5 do 16 let. Vyberte si z naší nabídky.`
	},
	{
		title: 'TEMATICKÉ AKTIVITY',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/aktivity.png',
			alt: ''
		},
		color: 'green',
		page: 'aktivity',
		template: `Tematické dětské aktivity a workshopy pro rodiče s dětmi. Vyberte si z naší nabídky.`
	}
];

export const camps = [
	{
		title: 'NOVINKA: POKÉMONI',
		color: 'orange2',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/pokemoni.png',
			alt: ''
		},
		address: 'ZŠ Jesenice, Praha – západ, ZŠ Zdiměřice, Praha - západ',
		dates: `<ul>
				<li><span>ZŠ Jesenice: 5. – 9. 8. 2024</span></li>
				<li><span>ZŠ Zdiměřice: 8. – 12. 7. 2024</span></li>
				</ul>`,
		datesForApplication: [
			{
				key: 'ZŠ Jesenice: 5. – 9. 8. 2024',
				value: 'ZŠ Jesenice: 5. – 9. 8. 2024'
			},
			{
				key: 'ZŠ Zdiměřice: 8. – 12. 7. 2024',
				value: 'ZŠ Zdiměřice: 8. – 12. 7. 2024'
			},
		],
		time: 'po–pá od 8:00 do 17:00',
		perex: 'Tento tábor bude skvělým zážitkem pro všechny děti, které jsou milovníci Pokémonů a rádi se o nich dozví něco nového. Na děti čeká krásný 5-ti denní program plný zábavy a nevšedních zážitků.',
		content: `  
 		<div class="program">
 			<ul>
 				<li><span>Budeme společně objevovat Oblázkové město a nové Pokémony</span></li>
				<li><span>Pokusíme se porazit tým Rakeťáků</span></li>
				<li><span>Děti si budou moci vyzkoušet karetní hru Battle Academy, ve které si poté uspořádáme turnaj</span></li>
				<li><span>Naučíme se rozpoznávat pravé karty od falešných</span></li>
				<li><span>Děti si vyrobí svého vlastního Pokémona a svou vlastní kartičku</span></li>
				<li><span>Zahrajeme si Pokémon divadlo a prozkoumáme svoje znalosti za pomoci různých kvízů a her</span></li>
				<li><span>Budeme sportovat a chytat Pokémony do Pokéballů</span></li>
				<li><span>Pojedeme na celodenní výlet do ZOO, kde nás čeká spousta zábavy</span></li>
				<li><span>Nebude chybět celotáborová hra <strong>Zachraňte Snorlaxe</strong></span></li>
				<li><span>Děti si vyzkoušejí nejrůznější akční a zábavné outdoorové aktivity a to i v přírodě s tématikou Pokémonů</span></li>
			</ul> 
			<span class="last-info" style="color: #F3672E">Děti, očekávejte tábor plný krásných zážitků a dobrodružství, těšíme se!</span>
		</div> 
		<div class="postinfo">
			<strong>Cena: 3 990 Kč / 5 dní (po-pá)</strong><br> 
			V ceně je zahrnutý <strong>celodenní výlet do pražské ZOO</strong>, 5× teplý oběd, dopolední/odpolední svačinka a pitný režim po celou dobu tábora.<br> 
			Děti je možné přihlásit i na jednotlivé dny v týdnu, cena bude individuálně vypočítána.<br>
			<strong>Nabízíme slevy pro sourozence v hodnotě 300 Kč.</strong> 
		</div>`
	},
	{
		title: 'NOVINKA: LETNÍ TÁBOROVÁ ŠKOLKA KAMARÁD',
		color: 'purple2',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/letni_taborova_skola.png',
			alt: ''
		},
		address: 'DTJ Velké Chuchle, Praha 5',
		dates: `<ul>
				<li><span>8. – 12. 7. 2024</span></li>
				<li><span>15. – 19. 7. 2024</span></li>
				<li><span>29. 7. – 2. 8. 2024</span></li>
				<li><span>19. – 23. 8. 2024</span></li>
				</ul>`,
		datesForApplication: [
			{
				key: '5: 8. – 12. 7. 2024',
				value: '8. – 12. 7. 2024'
			},
			{
				key: '15. – 19. 7. 2024',
				value: '15. – 19. 7. 2024'
			},
			{
				key: '29. 7. – 2. 8. 2024',
				value: '29. 7. – 2. 8. 2024'
			},
			{
				key: '19. – 23. 8. 2024',
				value: '19. – 23. 8. 2024'
			},
		],
		time: 'po–pá od 8:00 do 17:00',
		perex: 'Na děti čeká krásný 5-ti denní program plný zábavy a dobrodružství z různých odvětví. Tento tábor je připravený na míru speciálně pro děti předškolního věku od 4 do 6 let.',
		content: `  
 		<div class="program">
 			<ul>
 				<li><span>Děti budou sportovat, tvořit a bádat</span></li>
				<li><span>Proniknou do tajů pohádkové říše plné zvířecích kamarádů a pohádkových postav</span></li>
				<li><span>Naučíme se různé pohybové hry a vyzkoušíme si nové sporty</span></li>
				<li><span>Zahrajeme si loutkové divadlo a zazpíváme si pohádkové písničky</span></li>
				<li><span>Budeme vyrábět z různých materiálů, nebude chybět keramika malování na obličej a na plátno</span></li>
				<li><span>Děti si vyzkoušejí nejpopulárnější přírodovědné pokusy, budeme kouzlit a naučíme se tajná zaklínadla</span></li>
				<li><span>Naučíme se něco nového o zvířátkách, rostlinkách a o ekologii</span></li>
				<li><span>Zahrajeme si na piráty a námořníky, vytvoříme si pirátskou loď, vlajku a talisman</span></li>
				<li><span>Zavítáme do mořské říše a naučíme se vázat námořnické uzly</span></li>
				<li><span>Děti budou mít možnost jít po obědě spinkat nebo odpočívat při poslechu pohádek</span></li>
				<li><span>Nebude chybět celotáborová hra <strong>Hledání pokladu</strong> a <strong>balonková diskotéka</strong> na závěr</span></li>
			</ul> 
			<span class="last-info" style="color: #872563">Děti, očekávejte tábor plný krásných zážitků a dobrodružství, těšíme se!</span>
		</div> 
		<div class="postinfo">
			<strong>Cena: 2 990 Kč / 5 dní (po-pá)</strong><br> 
			V ceně je zahrnutý 5× teplý oběd, dopolední/odpolední svačinka a pitný režim po celou dobu tábora.<br>  
		</div>`
	},
	{
		title: 'HARRY POTTER, VÍTEJTE V BRADAVICKÉ ŠKOLE!',
		color: 'purple',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/harry_potter.png',
			alt: ''
		},
		address: 'ZŠ Zdiměřice, Praha - západ',
		dates: `<ul>
				<li><span>19. – 23. 8. 2024</span></li>
				</ul>`,
		datesForApplication: [
			{
				key: '19. – 23. 8. 2024',
				value: '19. – 23. 8. 2024'
			}
		],
		time: 'po–pá od 8:00 do 17:00',
		perex: 'Tento tábor bude skvělým zážitkem pro všechny děti, které milují Harryho Pottera a chtějí zažít dobrodružství a spoustu legrace. Tábor plný čar, kouzel a zábavných aktivit, na které děti jen tak nezapomenou.',
		content: `  
 		<div class="program">
 			<ul>
				<li><span>První den budou děti zařazeny do jednotlivých kolejí podle moudrého klobouku</span></li>
				<li><span>Výukou dětí doprovází zkušení profesoři a magická zvířata</span></li>
				<li><span>V naší škole čar a kouzel se děti naučí připravovat nejrůznější druhy lektvarů z kouzelných bylin a naučí se poznávat fantastická zvířata</span></li>
				<li><span>Děti si vyrobí svoji kouzelnickou hůlku, klobouk a plášť</span></li>
				<li><span>Děti se naučí číst ve starodávných runách a budou psát starodávnými brky</span></li>
				<li><span>Děti si zahrají netradiční formu famfrpálu</span></li>
				<li><span>Na děti čekají hodiny tajných pokusů a zaklínadel, nebude chybět hodina létání na koštěti a přeskok pavučin</span></li>
				<li><span>Na děti čeká mnoho outdoorovvých aktivit v přírodě</span></li>
				<li><span>Pojedeme na celodenní výlet do pražské ZOO</span></li>
				<li><span>Nebude chybět celotáborová hra na téma <strong>Kouzelný kámen mudrců</strong> a po celý týden budeme plnit nejrůznější zábavné a vědomostní úkoly</span></li>
			</ul> 
			<span class="last-info" style="color: #866bad">Děti, těšte se na tábor plný kouzel, čarování a zábavných aktivit!</span>
		</div> 
		<div class="postinfo">
			<strong>Cena: 3 990 Kč / 5 dní (po-pá)</strong><br>
			V ceně je zahrnutý <strong>celodenní výlet do pražské ZOO</strong>, 5× teplý oběd, dopolední/odpolední svačinka a pitný režim po celou dobu tábora<br>
			Děti je možné přihlásit i na jednotlivé dny v týdnu, cena bude individuálně vypočítána.<br>
			<strong>Nabízíme slevy pro sourozence v hodnotě 300 Kč.</strong>
		</div>`
	},
	{
		title: 'DOBRODRUŽNÝ A POZNÁVACÍ TÁBOR KAMARÁD',
		color: 'green',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/dobrodruzny.png',
			alt: ''
		},
		address: 'ZŠ Zdiměřice, Praha - západ',
		dates: `<ul>
				<li><span>8. – 12. 7. 2024</span></li>
				</ul>`,
		datesForApplication: [
			{
				key: '8. – 12. 7. 2024',
				value: '8. – 12. 7. 2024'
			}
		],
		time: 'po–pá od 8:00 do 17:00',
		perex: 'Tento tábor bude skvělým zážitkem pro všechny děti, které milují dobrodružství, přírodu, zvířata, výlety a outdoorové aktivity. Na děti čeká krásný 5-ti denní program plný zábavy a nevšedních zážitků.',
		content: `  
 		<div class="program">
 			<ul>
 				<li><span>Děti se mohou těšit na dobrodružné a sportovní outdoorové aktivity, poznávací výpravy do přírody, na zábavné hry a vědomostní úkoly</span></li>
				<li><span>Děti si vyrobí vlastní luk a šíp, zhotovíme si brašnu do přírody, dřevěnou ptáčí budku, herbář, vesmírnou loď a mnoho dalšího</span></li>
				<li><span>Zavítáme do zvířecí a rostlinné říše, budeme se aktivně vzdělávat na našich výpravách v přírodě</span></li>
				<li><span>Na děti čeká celodenní výlet do pražské ZOO</span></li>
				<li><span>Děti se budou učit skautským dovednostem a týmové spolupráci</span></li>
				<li><span>Naučíme se postavit stan, jak rozdělat oheň, vázat uzly, uděláme si několik přírodovědných pokusů</span></li>
				<li><span>Budeme se učit dopravním značkám, tématu Vesmír, základům astronomie a první pomoci</span></li>
				<li><span>Zaměříme se na ochranu přírody a téma ekologie</span></li>
				<li><span>Nebude chybět celotáborová hra na téma <strong>Trosečník</strong></span></li>
			</ul> 
			<span class="last-info" style="color: #5bb047">Děti, očekávejte tábor plný dobrodružství a krásných zážitků!</span>
		</div> 
		<div class="postinfo">
			<strong>Cena: 3 990 Kč / 5 dní (po-pá)</strong><br> 
			V ceně je zahrnutý <strong>celodenní výlet do pražské ZOO</strong>, 5× teplý oběd, dopolední/odpolední svačinka a pitný režim po celou dobu tábora.<br>
			Děti je možné přihlásit i na jednotlivé dny v týdnu, cena bude individuálně vypočítána.<br>
			<strong>Nabízíme slevy pro sourozence v hodnotě 300 Kč.</strong> 
		</div>`
	},
	{
		title: 'VÝTVARNÝ A TVŮRČÍ TÁBOR KAMARÁD',
		color: 'yellow',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/vytvarny.png',
			alt: ''
		},
		address: 'ZŠ Jesenice, Praha - západ',
		dates: `<ul>
				<li><span>22. – 26. 7. 2024</span></li>
				</ul>`,
		datesForApplication: [
			{
				key: '22. – 26. 7. 2024',
				value: '22. – 26. 7. 2024'
			},
		],
		time: 'po–pá od 8:00 do 17:00',
		perex: 'Náš umělecký a kreativní tábor bude skvělým zážitkem pro všechny děti, které milují umění, rády tvoří, kreslí a vyrábí z rozmanitých materiálů. Čeká na ně 5-ti denní program, ze kterého si odnesou domů spoustu krásných výrobků a zážitků.',
		content: `  
 		<div class="program">
 			<ul>
 				<li><span>Na děti čeká velice pestrý a kreativní program, ve kterém se zaměříme na netradiční výtvarné techniky a tvořivé aktivity</span></li>
				<li><span>Děti si vyzkoušejí tvořit z nejrůznějších materiálů a nebude chybět výroba z keramické a sochařské hlíny</span></li>
				<li><span>Děti si vytvoří vlastní šperk, talisman, ušijeme si módní tašku, spíchneme si jehelníček a pouzdro na psací potřeby</span></li>
				<li><span>Vydáme se do přírody a budeme malovat na plátno přírodní krásy jako pravý umělci</span></li>
				<li><span>Zkusíme si navrhnout vlastní komiksovou postavu a ztvárnit vlastní komiksový příběh</span></li>
				<li><span>Budeme malovat na obličej a na kameny</span></li>
				<li><span>Pojedeme na celodenní výlet do centra řemesel Botanicus Ostrá</span></li>
				<li><span>Nebude chybět celotáborová hra na téma <strong>malíři Picasso</strong></span></li>
				<li><span>Na děti čekají zábavné úkoly a outdoorové aktivity</span></li>
			</ul> 
			<span class="last-info" style="color: #ffdd00">Děti, očekávejte tábor plný krásných výrobkůa zážitků, těšíme se!</span>
		</div> 
		<div class="postinfo">
			<strong>Cena: 3 900 Kč / 5 dní (po-pá)</strong><br> 
			V ceně je zahrnutý <strong>celodenní výlet do centra řemesel Botanicus Ostrá.</strong>. V ceně je zahrnutý 5× teplý oběd, dopolední/odpolední svačinka a pitný režim po celou dobu tábora.<br>
			Děti je možné přihlásit i na jednotlivé dny v týdnu, cena bude individuálně vypočítána.<br>  
			<strong>Nabízíme slevy pro sourozence v hodnotě 300 Kč.</strong>
		</div>`
	},
	{
		title: 'PEVNOST BOYARD',
		color: 'bred',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/board.png',
			alt: ''
		},
		address: 'DTJ Velké Chuchle, Praha 5',
		dates: `<ul>
				<li><span>15. – 19. 7. 2024</span></li>
				</ul>`,
		datesForApplication: [
			{
				key: '15. – 19. 7. 2024',
				value: '15. – 19. 7. 2024'
			},
		],
		time: 'po–pá od 8:00 do 17:00',
		perex: 'Tento tábor bude skvělým zážitkem pro všechny děti, které rády soutěží, mají v sobě kus odvahy, mají rády outdoorové aktivity a dobrodružství. Na děti čeká krásný 5-ti denní program plný zábavy a nevšedních zážitků.',
		content: `  
 		<div class="program">
 			<ul>
 				<li><span>Na děti čekají nejrůznější dobrodružné a vědomostní úkoly</span></li>
				<li><span>Nebude chybět moudrý otec Fura a hádání tajemných indicií</span></li>
				<li><span>Děti si vyzkoušejí nejrůznější sportovní outdoorové aktivity, zábavné týmové hry a budou se učit týmové spolupráci</span></li>
				<li><span>Děti budou po celý týden soutěžit a nalézat klíče, kterými se na konci tábora dostanou k pokladu</span></li>
				<li><span>Pojedeme na celodenní výlet do pražské ZOO</span></li>
				<li><span>Budeme se věnovat tématu Vesmír, základům astronomie a vyzkoušíme si několik přírodovědných pokusů</span></li>
				<li><span>Na děti čeká celotáborová hra na téma <strong>Středověk</strong></span></li>
			</ul> 
			<span class="last-info" style="color: #e24040">Děti, očekávejte tábor plný dobrodružství a krásných zážitků, těšíme se!</span>
		</div> 
		<div class="postinfo">
			<strong>Cena: 3 990 Kč / 5 dní (po-pá)</strong><br> 
			V ceně je zahrnutý <strong>celodenní výlet do pražské ZOO</strong>, 5× teplý oběd, dopolední/odpolední svačinka a pitný režim po celou dobu tábora.<br> 
			Děti je možné přihlásit i na jednotlivé dny v týdnu, cena bude individuálně vypočítána.<br>
			<strong>Nabízíme slevy pro sourozence v hodnotě 300 Kč.</strong> 
		</div>`
	},
	{
		title: 'AVANGERS ANEB SUPERHRDINOVÉ',
		color: 'greenl',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/superhrdinove.png',
			alt: ''
		},
		address: 'DTJ Velké Chuchle, Praha 5',
		dates: `<ul>
				<li><span>29. 7. – 2. 8. 2024</span></li>
				</ul>`,
		datesForApplication: [
			{
				key: '29. 7. – 2. 8. 2024',
				value: '29. 7. –2. 8. 2024'
			},
		],
		time: 'po–pá od 8:00 do 17:00',
		perex: 'Tento tábor bude skvělým zážitkem pro všechny děti, které jsou akční, mají v sobě kus odvahy, mají rády outdoorové aktivity a dobrodružství. Na děti čeká krásný 5-ti denní program plný zábavy a nevšedních zážitků.',
		content: `  
 		<div class="program">
 			<ul>
				<li><span>Děti budou po celý týden získávat kameny nekonečna a budou se snažit porazit Thanose</span></li>
				<li><span>Děti si vyrobí svůj vlastní kostým Superhrdiny a budou rozděleny do superoddílů Batmana, Ironmana nebo Spidermana</span></li>
				<li><span>Po celý týden budou zjišťovat svoje mimořádné superschopnosti a budou soutěžit v různých disciplínách</span></li>
				<li><span>Děti si vyzkouší hod Thorovým kladivem, odolnost štítu Kapitána Ameriky, sílu Hulka a budeme zápolit se superpadouchy o osud světa</span></li>
				<li><span>Děti si vyzkoušejí střelbu z luku agentů S.H.I.E.L.D. a nebudou chybět ani Strážci Galaxie</span></li>
				<li><span>Pojedeme na celodenní výlet do pražské ZOO</span></li>
				<li><span>Postavíme si vědeckou laboratoř, kde si vyzkoušíme celou řadu přírodovědných pokusů</span></li>
				<li><span>Na děti čeká celotáborová hra na téma <strong>Nejlepší Superhrdina</strong></span></li>
				<li><span>Děti si vyzkoušejí nejrůznější akční a zábavné outdoorové aktivity a to i v přírodě s tématikou Superhrdinů</span></li>
			</ul> 
			<span class="last-info" style="color: #4CB732">Děti, očekávejte tábor plný krásných zážitků a dobrodružství, těšíme se!</span>
		</div> 
		<div class="postinfo">
			<strong>Cena: 3 990 Kč / 5 dní (po-pá)</strong><br> 
			V ceně je zahrnutý <strong>celodenní výlet do pražské ZOO</strong>, 5× teplý oběd, dopolední/odpolední svačinka a pitný režim po celou dobu tábora.<br> 
			Děti je možné přihlásit i na jednotlivé dny v týdnu, cena bude individuálně vypočítána.<br>
			<strong>Nabízíme slevy pro sourozence v hodnotě 300 Kč.</strong> 
		</div>`
	},
];

export const activities = [
	{
		title: 'PIRÁTI A NÁMOŘNÍCI',
		color: 'blue',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/pirati.png',
			alt: ''
		},
		content: `Pirátské odpoledne pro děti, ale i jejich rodiče, kde si společně vyzkoušíme
		vázání námořnických uzlů, lovení ryb, výrobu pirátské vlajky a pirátské
		lodě. Děti budou zdolávat nejrůznější překážky s páskou na očích
		a čekají na ně rozličné disciplíny s touto tematikou. Děti získají
		i nové poznatky o mořích, mořských živočiších a o životě námořníků
		a pirátů. Vyrobí si pirátské meče z balonků, pirátský šátek, který si
		na památku odnesou domů, a na závěr si všichni zatančíme tanec
		námořníků a pirátů. Samozřejmě nebudou chybět naši animátoři a lektoři
		v pirátských kostýmech. Těšíme se na námořnické a pirátské odpoledne
		s Vámi.`,
	},
	{
		title: 'HALLOWEENSKÁ PÁRTY',
		color: 'purple',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/halloween.png',
			alt: ''
		},
		content: `Vyhlašujeme soutěž o nejlepšího strašáka! Halloweenskou párty, na které
		si každé dítě vydlabe svého úžasného strašáka z dýně a budeme
		soutěžit o toho nejstrašidelnějšího. Děti si vytvoří i strašidelnou masku,
		u toho nás bude doprovázet lektor na kytaru a s ním si zazpíváme
		spoustu veselých dětských písniček. Mladší děti si mohou vytvořit
		halloweenského strašáka z balonku. Všichni naši lektoři budou oblečeni
		do krásně strašidelných halloweenských kostýmů.`,
	},
	{
		title: 'VÁNOČNÍ KERAMICKÉ TVOŘENÍ PRO RODIČE <br>S DĚTMI',
		color: 'blue-light',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/vanocni_keramika.png',
			alt: ''
		},
		content: `Pod vedením našeho zkušeného lektora keramiky si budou moci
		děti společně s rodiči vyrobit dárečky s vánoční tematikou. Rodiče si
		mohou přijít se svými dětmi „zapatlat“ a užít si tak společně tvořivou
		dvouhodinovku. Všechny výrobky odvezeme k nám do ateliéru vypálit
		a před Vánocemi si je děti odnesou domů.`,
	},
	{
		title: 'DĚTSKÝ DEN KAMARÁD',
		color: 'green',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/detsky_den.png',
			alt: ''
		},
		content: `Rádi bychom pro Vaše děti zorganizovali úžasný a originální dětský
		den nabitý nejrůznějšími aktivitami. Celý den bude děti bavit náš
		klaun a nebudou chybět další veselí pohádkoví maskoti. Na děti
		čekají nejrůznější sportovní a soutěžní disciplíny, žonglování,
		balonková diskotéka, bublinková supershow, malování na obličej
		a možná přijde i kouzelník. Všechny děti dostanou na památku
		od našeho klauna dárek. Tento den jim určitě vykouzlí úsměv
		na tváři a budou na něj dlouho vzpomínat. Rodiče jsou vítáni.`,
	},
	{
		title: '„ČÁRY MÁRY, MÁRY ČÁRY, HNI SE, KOŠTĚ, LÁRY -FÁRY“ SLET ČARODĚJNIC A ČERNOKNĚŽNÍKŮ!',
		color: 'green-light',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/cary_mary.png',
			alt: ''
		},
		content: `Čarodějnická slavnost pro děti plná magie, kouzel a zábavných
		aktivit, na které děti jen tak nezapomenou. Naše čarodějnice
		Kanimůra je zasvětí do tajů kouzel, naučí je tajná zaklínadla a uvaří
		si s nimi čarodějný lektvar z kouzelných bylin „pavoučí nožky
		a ropuší sliz“. Nebude chybět výroba čarodějných hůlek, velkého
		nosu ani malování na obličej a každé dítě si vyrobí vlastní malou
		čarodějnici, kterou si na památku odnese domů. Budeme soutěžit
		v disciplínách let na koštěti, házení koštětem do dálky, průlez a přeskok
		pavučin a vše vyvrcholí čarodějnickým rejem. Jako bonus jsme pro děti
		připravili maňáskové divadlo O perníkové chaloupce, kde se děti dozvědí
		i něco z historie čarodějnic a tohoto svátku. Slavnost probudí v dětech fantazii
		a tvořivost. Rodiče jsou vítáni. <strong>„Ježibaby, kamarádky, krouží vzduchem tam
		a zpátky. Šaty mají od sazí, snad se v letu nesrazí.“</strong>`,
	},
	{
		title: 'DRAKIÁDA',
		color: 'orange',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/drakiada.png',
			alt: ''
		},
		content: `Bude se létat, a to s draky, které si děti samy vyrobí s pomocí našich lektorů.
		Vítáni jsou i rodiče, kteří se s námi mohou zapojit do soutěžního klání
		o nejoriginálnějšího draka bez ohledu na létací schopnosti. Poté si zkusíme
		draka pustit a děti si ho odnesou domů. Na všechny čeká milé překvapení
		a malý dárek za odměnu.`,
	},
	{
		title: 'POHÁDKOVÝ KARNEVAL',
		color: 'yellow',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/karneval.png',
			alt: ''
		},
		content: `Náš veselý klaun Vás bude doprovázet celým odpolednem. Děti si s ním
		vyzkoušejí žonglování, zahrají si různé zábavné hry. Nebude chybět ani
		vodník Česílko, se kterým si děti uloví ryby v rybníce a vyzkoušejí si
		chytat dušičky do hrníčků. Vytvoříme si masky, zatančíme si společně
		s dešťovou vílou Majdalenkou, lesní vílou Amálkou a mechovou vílou
		Cilinkou a zazpíváme si pohádkové písničky. Nebude chybět ani
		loutkové divadlo a naši maskoti, kteří děti zavedou do říše pohádek.`,
	},
	{
		title: 'EXKURZE NA EKOFARMU KLÍNEC A AKTIVNÍ VÝLET <br>S KAMARÁDEM',
		color: 'pink',
		image: {
			src: 'https://krouzky-kamarad.cz/img/aktivity/ekofarma.png',
			alt: ''
		},
		content: `<strong>Plánujete školní výlet, a nevíte kam? Pojeďte s Kamarádem na EKOFARMU KLÍNEC!</strong>
		Dětské kroužky Kamarád si pro Vaše děti připravily zážitkový půldenní pobyt
		na ekofarmě Klínec spojený s aktivitou nazvanou <strong>KOUZELNÉ POHÁDKOVÉ
		SKOTAČENÍ</strong> a to vše v překrásné přírodě, v malebné obci ležící jen několik
		kilometrů od Prahy. Na děti zde čeká plno zvířátek, některá z nich si budou
		moci také pohladit a pochovat. Milý průvodce děti s jednotlivými zvířátky
		seznámí a také jim ukáže, jak se o zvířátka pěkně starají. V případě zájmu
		a hezkého počasí se děti budou moci povozit i na ponících. Nebude
		chybět ani dobrý oběd a bohatý program, které jsme pro Vaše děti
		připravili. Dopravu na farmu a zpět zajistíme. Věříme, že tento výlet
		potěší nejen děti, ale i Váš pedagogický personál.`,
	},
];
