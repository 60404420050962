<template>
	<div class="activity__list">
		<router-link :to="{ name: 'app', params: { section: column.page }}" class="activity__list__column" v-for="(column, idx) in types" :key="idx">
			<img :src=column.image.src :alt=column.image.alt />
			<div class="activity__list__column__container">
				<h2 :class="[ 'activity__list__column__title', 'activity__list__column__title--' + column.color ]">{{ column.title }}</h2>
				<div class="activity__list__column__info" v-html="column.template" />
			</div>
		</router-link>
	</div>
</template>

<script>
	import { types } from '../../data/applicationTypes';

	export default {
		data: function () {
			return {
				types: types
			}
		}
	}
</script>

<style scoped>

</style>
