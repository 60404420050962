<template>
	<nav>
		<ul>
			<li
				v-for="(section, idx) in sections"
				:key="idx"
			>
				<a v-if="section.title === 'facebook'" href="https://www.facebook.com/krouzkykamarad" target="_blank">
					<span class="facebook"></span>
				</a>
				<span v-else>
					<router-link v-if="section.url" :to="{ name: 'app', params: { section: section.url.sections, subsection: section.url.subsection }}">
						{{ section.title }}
					</router-link>
					<span v-else>{{ section.title }}</span>
				</span>
			</li>
		</ul>
	</nav>
</template>

<script>
	export default {
		name: 'FooterMenu',
		props: {
			sections: Array
		}
	}
</script>

<style lang="less" scoped>
	ul {
		margin: 0;
		list-style: none;

		li {
			font-size: 12px;
			color: #2E2A4D;
			text-transform: lowercase;
			padding: 5px;

			a {
				color: #2E2A4D;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.facebook {
		width: 30px;
		height: 30px;
		background: url('../assets/fb.png') no-repeat center center;
		display: block;
	}
</style>