<template>
	<div>
		<div class="application" v-html="getDetail().template"></div>

		<div class="application">
			<router-link :v-if="this.$route.params.subsection" :to="{ name: 'app', params: getApplicationParams()}">
				<span :class="['more-detail', 'more-detail--' + getDetail().color]">Přihláška</span>
			</router-link>
		</div>
	</div>
</template>

<script>

	import { detail } from '../../data/detail';
	import { ms, zs } from '../../data/offer';

	export default {
		name: 'Application',
		methods: {
			getDetail() {
				if (detail[this.$route.params.subsection]) {
					return detail[this.$route.params.subsection];
				}

				return {
					template: `<p>Detail kroužku pro Vás připravujeme</p>`
				};
			},
			getApplicationParams() {
				let detail = null;

				zs.forEach(zsItem => {
					if (!detail) {
						const list = zsItem.list.find(itemList => itemList.page === this.$route.params.subsection);
						if (list) {
							detail = {
								section: 'prihlaska',
								subsection: 'zs',
								subsubsection: zsItem.title,
								detail: list.page
							}
						}
					}
				});

				if (!detail) {
					ms.forEach(msItem => {
						if (!detail) {
							const list = msItem.list.find(itemList => itemList.page === this.$route.params.subsection);
							if (list) {
								detail = {
									section: 'prihlaska',
									subsection: 'ms',
									subsubsection: msItem.title,
									detail: list.page
								}
							}
						}
					});
				}

				return detail;
			}
		}

	}
</script>

<style lang="less">
	.application {
		padding-top: 45px;
		text-align: center;

		a {
			text-decoration: none !important;
		}

		h2 {
			font-family: 'Caveat Brush';
			font-size: 50px;
			margin-top: 0px;
		}

		ul {
			text-align: left;

			li {
				font-size: 20px;
				line-height: 1;
				
				span {
					font-size: 14px;
					line-height: 1.7;
					position: relative;
					left: -5px;
				}
			}
		}
	}

	.more-detail {
		border-radius: 4px;
		width: 150px;
		display: block;
		color: #fff;
		text-align: center;
		padding: 14px 0;
		font-size: 20px;
		margin: 0 auto;

		&--pink {
			background: #BA3F8C 0% 0% no-repeat padding-box;
		}

		&--green {
			background: #5FB14D 0% 0% no-repeat padding-box;
		}

		&--ms-green {
			background: #0086BA 0% 0% no-repeat padding-box;
		}

		&--orange {
			background: #FF8B22 0% 0% no-repeat padding-box;
		}

		&--ms-orange {
			background: #F2915C 0% 0% no-repeat padding-box;
		}

		&--zs-orange {
			background: #ff8e4f 0% 0% no-repeat padding-box;
		}

		&--blue	{
			background: #2e2a4d 0% 0% no-repeat padding-box;
		}

		&--purple {
			background: #623F90 0% 0% no-repeat padding-box;
		}

		&--yellow {
			background: #FFCB00 0% 0% no-repeat padding-box;
		}
	}
</style>