<template>
	<div class="application" v-html="getTemplate()"></div>
</template>

<script>
	export default {
		name: 'Info',
		data: function () {
			return {
				pages: {
					'o-nas': {
						template: `
<p><img src="https://krouzky-kamarad.cz/img/onas.png" class="fix_width" alt="" /></p>
<p>Jsme společnost Dětské kroužky Kamarád zabývající se pořádáním zájmových kroužků pro děti v
základních a mateřských školách. Máme několikaleté zkušenosti v této oblasti. Děti se mohou těšit na naši <strong>pestrou
a moderní nabídku sportovních, tanečních, jazykových, edukativních, divadelních, hudebních, výtvarných a
tvořivých kroužků</strong>.</p>

<p>Naším posláním je pomoci dětem nalézt oblast, ve které mohou vyniknout, probudit v dětech vášeň, zvědavost a
zájem o vybraný kroužek. <strong>Radost a spokojenost dětí je u nás na prvním místě a je pro nás největší odměnou</strong>.</p>

<p>V našich kroužcích umožňujeme dětem smysluplně prožít volný čas, podporujeme u dětí zdravé návyky a
vytváříme bezpečné prostředí, kde se děti nebojí kritiky a posuzování. Naše kroužky jsou nejen zábavné, ale i
naučné, děti se v nich dokáží uvolnit a přirozenou formou rozvíjejí svoje dovednosti, fantazii a kreativní myšlení.
Naši lektoři mají bohaté zkušenosti s dětmi, jsou empatičtí, dokáži děti vtáhnout do kroužku a přirozeně je
motivovat ke spolupráci. Dělá nám neuvěřitelnou radost, když vidíme, že se děti do našich kroužků rády vracejí a
těší se na nás.</p>

<p><strong>Velmi si zakládáme na výběru našich lektorů a na kvalitě našich kroužků</strong>. Náš lektorský tým je tvořen lidmi,
kteří práci s dětmi milují, jsou profesionální a zodpovědní.</p>

<p>Přejeme si, aby naše kroužky byly dostupné všem dětem, a to i dětem z finančně znevýhodněných rodin.
Snažíme se maximálně s cenou vyjít vstříc rodinám s tíživou finanční situací a rodičům samoživitelům.</p>

<p>Těšíme se na Vaše děti v našich kroužcích.</p>
<p><img src="https://krouzky-kamarad.cz/img/onas_mrak.png" alt="" /></p>
						`
					},
					kontakt: {
						template: `
							<div style="font-size: 20px;">
							<p><img src="https://krouzky-kamarad.cz/img/kontakt.png" class="fix_width" alt="" /></p>
							<h2 class="purple">Kontakt</h2>

							<p class="center pb10">
								<strong>Pro rodiče:</strong><br>
								Tomáš Volf<br>
								+420 605 057 005<br>
								volf@krouzky-kamarad.cz<br>
								tabory@krouzky-kamarad.cz
							</p>

							<p class="center pb10">
								<strong>Pro ředitele:</strong><br>
								Mgr. Věra Rafajová<br>
								+420 605 525 001
							</p>

							<p class="center pb10">
								<strong>Pro lektory:</strong><br>
								+420 602 214 860
							</p>

							<p class="center pb10">
								<strong>Sídlo společnosti / adresa kanceláře:</strong><br>
								Dětské kroužky Kamarád s.r.o.<br>
								Na Florenci 1686/9<br>
								Praha 1 - Nové Město<br>
								110 00 Praha 1
							</p>

							<p class="center">
								<strong>info@krouzky-kamarad.cz</strong>
							</p>
							</div>
						`
					},
					'obecne-informace': {
						template: `
						<div style="font-size: 20px;">
							<p><img src="https://krouzky-kamarad.cz/img/obecne-informace.png" class="fix_width" alt="" /></p>
							<h2 class="purple">Všeobecné informace</h2>
							<div class="center">
								<span class="no-link">Pro rodiče</span><br>
								<ul>
									<li><a href="https://krouzky-kamarad.cz/docs/rodice.pdf">Provozní řád</a></li>
								</ul>
								<a href="https://krouzky-kamarad.cz/docs/reditele_ms.pdf">Pro ředitele MŠ</a><br>
								<a href="https://krouzky-kamarad.cz/docs/reditele_zs.pdf">Pro ředitele ZŠ</a><br>
							</div>
							</div>
						`
					}
				}
			}
		},
		methods: {
			getTemplate () {
				if (this.$route.params.subsection && this.pages[this.$route.params.subsection]) {
					return this.pages[this.$route.params.subsection].template;
				}

				return `<p class="application--center">připravujeme</p>`;
			}
		}
	}
</script>

<style lang="less">
	.application {
		width: 670px;
		margin: 0 auto;
		padding-top: 65px;
		text-align: center;
		line-height: 1.7;

		p {
			padding-bottom: 25px;
		}

		.pb10 {
			padding-bottom: 10px;
		}

		img.fix_width {
			width: 350px;
		}

		h2 {
			color: #2E2A4D;
		}

		h2.purple {
			color: #623F90;
		}

		a {
			color: #2E2A4D;
			text-decoration: underline;
			font-weight: bold;

			&:hover {
				text-decoration: none;
				color: #000;
				border-bottom: 1px solid #000;
			}
		}

		.no-link {
			color: #2E2A4D;
			font-weight: bold;
		}

		ul {
			width: 355px;
			padding: 10px 0;
			margin: 0 0 0 50%;
		}
	}

	@media screen and (max-width: 700px) {
		.application {
			width: 100%;
		}
	}
</style>
