export const types = [
    {
        title: 'Přihláška na kroužek',
        image: {
            src: 'https://krouzky-kamarad.cz/img/krouzky/ms.png',
            alt: ''
        },
        color: 'green',
        page: 'prihlaska',
        template: ``
    },
    {
        title: 'Přihláška na příměstský tábor',
        image: {
            src: 'https://krouzky-kamarad.cz/img/aktivity/tabory.png',
            alt: ''
        },
        color: 'red',
        page: 'prihlaska-na-tabor',
        template: ``
    }
];
