<template>
	<div class="application">

		<h2>Přihláška na příměstský tábor</h2>

		<p class="alert">
			Vážení rodiče, v případě, že od nás neobdržíte na Váš email potvrzující odpověď o úspěšném
			přihlášení Vašeho dítěte na příměstský tábor, prosím, kontaktujte nás na e-mailu tabory@krouzky-kamarad.cz
			nebo na tel. čísle +420 605 057 005.
		</p>

		<div class="application__board">

			<h3 class="application__board__title">Vyberte typ přímestského tábora</h3>
			<div class="row row--justify-start">
				<div class="row--full">
					<CustomSelect ref="krouzek" :options="getCamps()" v-model="applicationModel.camp" />
				</div>
			</div>

			<h3 class="application__board__title">Vyberte termín a místo konání</h3>
			<div class="row row--justify-start">
				<div class="row--full">
					<CustomSelect ref="krouzek" :options="getDatesAndPlaces()" v-model="applicationModel.date" />
				</div>
			</div>

			<div class="row">
				<div class="row--full">
					<div class="application__board__info">Přihlašovací údaje</div>
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>Jméno a příjmení dítěte</label>
					<input type="text" v-model="applicationModel.nameSurname" />
				</div>
				<div class="row--half">
					<label>Datum narození dítěte</label>
						<input type="text" v-model="applicationModel.rc" />
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>Ulice</label>
					<input type="text" v-model="applicationModel.street" />
				</div>
				<div class="row--half">
					<label>Číslo popisné</label>
						<input type="text" v-model="applicationModel.houseNumber" />
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>Město</label>
					<input type="text" v-model="applicationModel.city" />
				</div>
				<div class="row--half">
					<label>PSČ</label>
						<input type="text" v-model="applicationModel.psc" />
				</div>
			</div>

			<div class="row row--justify-start">
				<div class="row--half">
					<label>Jméno zákonného zástupce</label>
					<input type="text" v-model="applicationModel.parentName" />
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>E-mail</label>
					<input type="text" v-model="applicationModel.email" />
				</div>
				<div class="row--half">
					<label>Telefon (např. +420603200300)</label>
					<input type="text" v-model="applicationModel.telephone" />
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>Poznámka (Kamarád do skupiny, potravinová intolerance, zdravotní omezení atd.)</label>
					<textarea rows="5" v-model="applicationModel.comment"></textarea>
				</div>
				<div class="row--half row--submit">
					<input type="submit" value="Přihlásit" @click="submit" />
				</div>
			</div>

			<div class="info" @click="setSouhlas">
				<div class="checkbox">
					<p-check name="check" class="p-default" color="primary" v-model="applicationModel.souhlas"></p-check>
				</div>
				<div class="text">

					Potvrzuji, že dítě je zdravotně způsobilé k absolvování příměstského tábora
					Kamarád a že jsem se seznámil/a s Všeobecnými podmínkami a náplní příměstského tábora
					uvedeném na tomto webu. Dále souhlasím jako zákonný zástupce s tím, aby osobní údaje
					vyplněné v kontaktním formuláři byly evidovány společností Dětské kroužky Kamarád s.r.o.,
					se sídlem: Vraný 167, 273 73 Vraný, IČO: 08987181 pro účely komunikace s účastníkem
					příměstských táborů Kamarád a jeho zákonným zástupcem (viz <a target="_blank" href="https://krouzky-kamarad.cz/docs/rodice.pdf">Všeobecné podmínky Kamarád s.r.o. a GDPR</a>).
				</div>
			</div>

			<div class="sended" v-if="sended">
				Děkujeme. Vaše přihláška byla odeslána. Co nejdříve se Vám ozveme.
			</div>

			<div class="errors" v-if="errors.length > 0">
				<ul>
					<li
						v-for="(error, id) in errors"
						:key="id"
					>{{ error }}</li>
				</ul>
			</div>

		</div>
	</div>
</template>

<script>

	import CustomSelect from '../form/CustomSelect';
	import ApplicationModelCamp from '../../data/ApplicationModelCamp';
	import { camps } from '../../data/activity';
	import emailjs from 'emailjs-com';

	export default {
		name: 'ApplicationCamp',
		data() {
			return {
				sended: false,
				errors: [],
				applicationModel: new ApplicationModelCamp(),
			};
		} ,
		methods: {
			setSouhlas: function() {
				this.applicationModel.souhlas = !this.applicationModel.souhlas;
			},
			getCamps: function() {
				return camps.map(camp => {
					return {
						key: camp.title,
						value: camp.title
					}
				});
			},
			getDatesAndPlaces: function() {
				const camp = camps.find(item => item.title === this.applicationModel.camp);

				if (!camp) {
					return [];
				}

				return camp.datesForApplication;
			},
			submit: function() {
				this.errors = [];
				const errors = this.applicationModel.validate();
				if (errors.length > 0) {
					this.errors = errors;
					return;
				}

				if (this.sended) {
					return;
				}

				this.sended = true;
				emailjs.send('mailgun','template_tklg97l', { email: this.applicationModel.email}, 'user_xpnY0JFvHxz5QLz4zV4Fh');
				emailjs.send('mailgun','template_oo7j8so', this.applicationModel.getEmailParams(), 'user_xpnY0JFvHxz5QLz4zV4Fh')
					.then(() => {
						this.applicationModel.reset();
						this.$refs.krouzek.setSelected({ value: null });
					}, () => {
						this.errors.push('Vyskytly se problémy s odesláním přihlášky. Kontaktujte nás prosím na telefonu +420 605 525 001');
					}
				);
			}
		},
		components: {
			CustomSelect
		},
	}
</script>

<style lang="scss">

	$pretty--color-primary:#4DBAE7;
	@import '~pretty-checkbox/src/pretty-checkbox.scss';

	.pretty {
		margin: 10px 0;

		.state {
			label {
				padding-right: 20px;
				display: inherit;

				&::before {
					left: auto;
					right: 0px;
				}
				&::after {
					left: auto;
					right: 0px;
				}
			}
		}
	}

	.application {
		padding-top: 45px;
		text-align: center;

		.alert {
			font-weight: bold;
		}

		&__board {
			border: 1px solid black;
			background-color: #fff;
			width: 480px;
			margin: 0 auto;
			padding: 30px 40px;
			text-align: left;

			&__title {
				font-size: 14px;
				text-align: left;
			}

			&__info {
				color: #65C4EA;
				font-size: 10px;
			}
		}

		&--full {
			width: 100%;
		}

		&--half {
			width: 50%;
		}

		&--middle {
			width: 30%;
		}

		&--short {
			width: 15%;
		}
	}

	.info {
		display: flex;

		.checkbox {
			width: 30px;
		}

		.text {
			width: 100%;
			padding: 20px 0 0 20px;
			font-size: 11px;
			line-height: 1.1;
		}
	}

	.errors {

		background-color: #CA4B40;
		border-radius: 15px;
		padding: 20px;
		margin-top: 20px;

		ul {
			margin: 0px 0;
			padding: 0px;
			list-style: none;

			li {
				font-size: 16px !important;
				color: #fff;
				padding: 0px 0px 10px;
			}
		}
	}

	.sended {
		background-color: #82C9ED;
		border-radius: 15px;
		padding: 20px;
		margin-top: 20px;
		color: #fff;
		font-size: 16px;
		text-align: center;
		font-weight: bold;
	}

	.row {
		width: 100%;
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		&--input-100 {
			input {
				width: 100% !important;
			}
		}

		&--justify-start {
			justify-content: start;
		}

		&--pl-30 {
			padding-left: 30px;
		}

		&--no-margin {
			margin-bottom: 0px;
		}

		&--full {
			width: 100%;
		}

		&--half {
			width: 50%;
		}

		&--submit {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 10px;

			input {
				color: #fff !important;
				background-color: #4DBAE7;
				border-bottom: 3px solid #3594D4 !important;
				border-left: 0px !important;
				border-right: 0px !important;
				border-top: 0px !important;
				width: 90% !important;
				height: 30px;
				position: relative;
				top: 3px;
			}
		}

		label {
			display: block;
			font-size: 12px;
		}

		input {
			border: 1px solid #999999;
			border-radius: 3px;
			padding: 6px;
			color: #65C4EA;
			width: 85%;
		}

		textarea {
			width: 85%;
			font-family: 'Raleway Regular', arial;
		}
	}

	@media screen and (max-width: 560px) {
		.application {
			&__board {
				width: 96%;
				padding: 0px;
				border: 0px;
				padding: 2%;
			}
		}

		.row--pl-30 {
			padding-left: 0px;
		}

		.row--half {
			width: 95%;
			padding-left: 5%;
		}
	}
</style>
