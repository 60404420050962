<template>
	<div id="app">
		<Header :sections="sections" />
		<Content />
		<Footer :sections="sections" />
	</div>
</template>

<script>
import Header from './components/Header.vue'
import Content from './components/Content.vue'
import Footer from './components/Footer.vue'

const sections = [
	{
		title: 'DOMŮ',
		url: { sections: '' }
	},
	{
		title: 'o nás',
		url: { sections: 'info', subsection: 'o-nas' }
	},
	{
		title: 'nabídka kroužků',
		url: { sections: 'nabidka-krouzku'}
	},
	{
		title: 'přihláška',
		url: { sections: 'vyber-prihlasky' }
	},
	{
		title: 'všeobecné informace',
		url: { sections: 'info', subsection: 'obecne-informace' }
	},
	{
		title: 'kontakt',
		url: { sections: 'info', subsection: 'kontakt' }
	},
];

export default {
	name: 'App',
	data: function() {
		return {
			sections,
		}
	},
	components: {
		Header,
		Content,
		Footer
	},
}
</script>

<style lang="less">

	@import url(https://cs.allfont.net/allfont.css?fonts=raleway-regular);
	@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap');

	body {
		margin: 0;
		padding: 0;
		font-family: 'Raleway Regular', arial;
		color: #2E2A4D;
		font-size: 14px;
	}

	.bm-burger-button {
		display: none;
	}

	.bm-menu {
		background-color: #2e2a4d !important;
	}

	a {
		text-decoration: none;
	}

	@media screen and (max-width: 500px) {
		img {
			max-width: 100%;
		}
	}

	@media screen and (max-width: 900px) {
		.bm-burger-button {
			display: block;
			z-index: 999;
		}
	}
</style>
