<template>
	<div class="content">
		<div class="content__cloud-top"></div>
		<div class="content__content">
			<Homepage v-if="!$route.params.section" />
			<Offer v-if="$route.params.section === 'nabidka-krouzku'" />
			<ApplicationChooser v-if="$route.params.section === 'vyber-prihlasky'" />
			<Application v-if="$route.params.section === 'prihlaska'" />
			<ApplicationCamp v-if="$route.params.section === 'prihlaska-na-tabor'" />
			<Activity v-if="$route.params.section === 'primestske-tabory-a-tematicke-aktivity'" />
			<Detail v-if="$route.params.section === 'detail'" />
			<Info v-if="$route.params.section === 'info'" />
		</div>

		<div class="content__cloud-bottom"></div>
	</div>
</template>

<script>

	import Homepage from './pages/Homepage';
	import Offer from './pages/Offer';
	import Application from './pages/Application';
	import Activity from './pages/Activity';
	import Detail from './pages/Detail';
	import Info from './pages/Info';
	import ApplicationChooser from "@/components/pages/ApplicationChooser";
	import ApplicationCamp from "@/components/pages/ApplicationCamp";

	export default {
		name: 'Content',
		data: function() {
			return {
				windowWidth: 0,
				windowHeight: 0,
				footerCorrection: 0,
			}
		},
		components: {
			ApplicationCamp,
			ApplicationChooser,
			Homepage,
			Offer,
			Application,
			Activity,
			Detail,
			Info
		}
	}
</script>

<style lang="less" scoped>
	.content {
		background: transparent linear-gradient(180deg, #BDE4FA 0%, #37B1E6 100%) 0% 0% no-repeat padding-box;
		min-height: 400px;

		&__content {
			min-height: 400px;
		}

		&__cloud-top {
			background-image: url('../assets/cloud_blue.png');
			height: 115px;
			background-size: contain;
			background-repeat: repeat-x;
			background-position: center top;
			position: relative;
			top: -1px;
		}

		&__cloud-bottom {
			background-image: url('../assets/cloud_transparent.png');
			height: 115px;
			background-size: contain;
			background-repeat: repeat-x;
			background-position: center bottom;
		}
	}

	@media screen and (max-width: 1200px) {
		.content {
			&__cloud-top, &__cloud-bottom {
				background-size: cover;
			}
		}
	}
</style>
