<template>
	<div class="homepage__menu__column">
		<router-link :to="{ name: 'app', params: { section: homepageItem.page }}">
			<div>
				<h2>{{ homepageItem.title }}</h2>
				<p>{{ homepageItem.description }}</p>
			</div>
			<img :src=homepageItem.image.src :alt=homepageItem.image.alt />
		</router-link>
	</div>
</template>

<script>
	export default {
		name: 'HomepageColumn',
		props: {
			homepageItem: Object
		}
	}
</script>
