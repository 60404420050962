<template>
	<div class="offer">
		<h2 v-if="!$route.params.subsection">Nabídka kroužků</h2>
		<div class="offer__list">
			<div class="offer__list__column" v-for="(column, idx) in this.getColumn()" :key="idx">
				<img :src=column.image.src :alt=column.image.alt />
				<div class="offer__list__column__container">
					<h2 :class="[ 'offer__list__column__title', 'offer__list__column__title--' + column.color ]">{{ column.title }}</h2>
					<div class="offer__list__column__info" v-html="column.template" />
					<ul v-if="column.list">
						<li v-for="(item, idx) in column.list" :key="idx">
							<router-link :to="{ name: 'app', params: { section: 'detail', subsection: item.page }}">
							{{ item.title }}
							</router-link>
						</li>
					</ul>
					<router-link v-if="!$route.params.subsection" :to="{ name: 'app', params: { section: 'nabidka-krouzku', subsection: column.page }}">
						<span :class="['more', 'more--' + column.color]">Více</span>
					</router-link>
					<router-link v-else :to="{ name: 'app', params: { section: 'prihlaska', subsection: $route.params.subsection, subsubsection: column.title }}">
						<span :class="['more', 'more--' + column.color]">Přihláška</span>
					</router-link>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import { columns, zs, ms } from '../../data/offer';

	export default {
		name: 'Offer',
		data: function () {
			return {
				columns: columns,
				zs: zs,
				ms: ms
			}
		},
		methods: {
			getColumn() {
				if (!this.$route.params.subsection) {
					return this.columns;
				}

				if (this.$route.params.subsection === 'ms') {
					return this.ms;
				}

				if (this.$route.params.subsection === 'zs') {
					return this.zs;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.offer {

		width: 900px;
		margin: 0 auto;

		h2 {
			font-family: 'Caveat Brush';
			font-size: 30px;
			text-transform: uppercase;
			color: #2E2A4D;
			text-align: center;
			padding: 90px 0 0;
			margin-bottom: 10px;
		}

		&__list {
			display: flex;
			padding-top: 30px;
			flex-wrap: wrap;
			justify-content: space-between;

			&__column {

				display: flex;
				align-items: flex-start;
				width: 45%;
				padding: 0 0 70px;

				&:nth-child(even) {
					position: relative;
					left: 45px;
				}

				&__info {
					margin-left: 25px;
					padding-right: 10px;
				}

				&__container {
					display: flex;
					flex-direction: column;
					height: 100%;

					ul {
						flex-grow: 1;
					}
				}

				h2&__title {
					font-family: 'Caveat Brush';
					font-size: 30px;
					text-transform: uppercase;
					padding: 0px;
					margin-top: 0px;
					margin-left: 25px;
					text-align: left;

					&--pink {
						color: #BA3F8C;
					}

					&--green {
						color: #5FB14D;
					}

					&--orange {
						color: #FF8B22;
					}

					&--ms-green {
						color: #0086BA;
					}

					&--blue	{
						color: #2e2a4d;
					}
					
					&--purple {
						color: #623F90;
					}

					&--yellow {
						color: #FFCB00;
					}
				}

				ul {
					margin: 0px;

					li {
						font-size: 20px;
						line-height: 1;
					}
					
					a {
						font-size: 14px;
						color: #2E2A4D;
						text-decoration: none;
						position: relative;
						left: -5px;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	.more {
		border-radius: 4px;
		width: 75px;
		display: block;
		color: #fff;
		text-align: center;
		padding: 7px 0;
		margin: 15px 25px 0;

		&--pink {
			background: #BA3F8C 0% 0% no-repeat padding-box;
		}

		&--green {
			background: #5FB14D 0% 0% no-repeat padding-box;
		}

		&--orange {
			background: #FF8B22 0% 0% no-repeat padding-box;
		}
		&--ms-green {
			background: #0086BA 0% 0% no-repeat padding-box;
		}

		&--zs-orange {
			background: #ff8e4f 0% 0% no-repeat padding-box;
		}

		&--blue	{
			background: #2e2a4d 0% 0% no-repeat padding-box;
		}

		&--purple {
			background: #623F90 0% 0% no-repeat padding-box;
		}

		&--yellow {
			background: #FFCB00 0% 0% no-repeat padding-box;
		}
	}

	@media screen and (max-width: 900px) {
		.offer {
			width: 100%;

			&__list {
				flex-direction: column;

				&__column {
					width: 100%;
					justify-content: center;

					&:nth-child(even) {
						position: relative;
						left: 0px !important;
					}
				}
			}
		}
	}

	@media screen and (max-width: 310px) {
		.offer {
			
			&__list {
				flex-direction: column;

				&__column {
					flex-direction: column;
					align-items: center;
				}
			}
		}
	}
</style>