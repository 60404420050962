import Vue from 'vue';
import VueRouter from 'vue-router'
import Vuex from 'vuex';
import App from './App.vue';
import PrettyCheck from 'pretty-checkbox-vue/check';
import VueGtag from "vue-gtag";

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.component('p-check', PrettyCheck);

const store = new Vuex.Store({
	state: {
		currentPage: 'homepage'
	},
	mutations: {
		setPage (state, newPage) {
			state.currentPage = newPage;
		}
	}
});

const routes = [
	{ path: '/:section?/:subsection?/:subsubsection?/:detail?', component: App, name: 'app' }
];

const router = new VueRouter({
	mode: 'history',
	routes
});

Vue.config.productionTip = false

Vue.use(VueGtag, {
	config: { id: 'UA-174307543-1' }
}, router);

new Vue({
	store,
	router,
	template: `<div id="app"><router-view></router-view></div>`
}).$mount('#app')
