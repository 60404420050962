export default class ApplicationModelCamp
{
	constructor() {
		this.camp = '';
		this.date = '';
		this.nameSurname = '';
		this.rc = '';
		this.street = '';
		this.houseNumber = '';
		this.city = '';
		this.psc = '';
		this.parentName = '';
		this.email = '';
		this.telephone = '';
		this.comment = '';
		this.souhlas = false;
	}

	reset() {
		this.camp = '';
		this.date = '';
		this.nameSurname = '';
		this.rc = '';
		this.street = '';
		this.houseNumber = '';
		this.city = '';
		this.psc = '';
		this.parentName = '';
		this.email = '';
		this.telephone = '';
		this.comment = '';
		this.souhlas = false;
	}

	validate() {
		const errors = [];
		if (!this.camp) { errors.push('Vyberte, prosím, typ tábora.'); }
		if (!this.date) { errors.push('Vyberte, prosím, termín tábora.'); }
		if (!this.nameSurname) { errors.push('Zadejte, prosím, jméno a příjmení dítěte.'); }
		if (!this.rc) { errors.push('Zadejte, prosím, datum narození dítěte.'); }
		if (!this.street) { errors.push('Zadejte, prosím, ulici.'); }
		if (!this.houseNumber) { errors.push('Zadejte, prosím, číslo popisné.'); }
		if (!this.city) { errors.push('Zadejte, prosím, město.'); }
		if (!this.psc) { errors.push('Zadejte, prosím, PSČ.'); }
		if (!/\d{3} ?\d{2}/.test(this.psc)) { errors.push('Zadejte, prosím, správný tvar PSČ, např. 530 02.'); }
		if (!this.parentName) { errors.push('Zadejte, prosím, jméno zákoného zástupce.'); }
		if (!this.email) { errors.push('Zadejte, prosím, e-mail.'); }
		if (!/[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(this.email)) { errors.push('Zadejte, prosím, správný tvar e-mailu, např. info@krouzky-kamarad.cz.'); }
		if (!this.telephone) { errors.push('Zadejte, prosím, telefon.'); }
		if (!/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/.test(this.telephone)) { errors.push('Zadejte, prosím, správný tvar telefonu, např. +420603200300.'); }
		if (this.souhlas === false) { errors.push('Zaškrtněte, prosím, Souhlas s Provozním řádem a GDPR.'); }

		return errors;
	}

	getEmailParams() {
		return {
			camp: this.camp,
			date: this.date,
			nameSurname: this.nameSurname,
			rc: this.rc,
			street: this.street,
			houseNumber: this.houseNumber,
			city: this.city,
			psc: this.psc,
			parentName: this.parentName,
			email: this.email,
			telephone: this.telephone,
			comment: this.comment
		};
	}
}
