<template>
	<div>
		<nav>
			<ul>
				<li
					v-for="(section, idx) in sections"
					:key="idx"
				>
					<router-link :to="{ name: 'app', params: { section: section.url.sections, subsection: section.url.subsection }}">
						{{ section.title }}
					</router-link>
				</li>
			</ul>
		</nav>
		<Slide 
			right
			:closeOnNavigation="true"
		>
			<router-link 
				v-for="(section, idx) in sections"
				:key="'r_' + idx"
				:to="{ name: 'app', params: { section: section.url.sections, subsection: section.url.subsection }}"
				class="smart__header"
			>
				<span>{{ section.title }}</span>
			</router-link>
		</Slide>
	</div>
</template>

<script>
	import { Slide } from 'vue-burger-menu'

	export default {
		name: 'TopMenu',
		props: {
			sections: Array
		},
		components: {
			Slide
		}
	}
</script>

<style lang="less" scoped>
	
	nav {
		width: 900px;
		margin: 0 auto;
	}
	
	ul {
		margin: 0;
		list-style: none;
		padding: 13px;
		display: flex;
		justify-content: space-between;

		li {
			color: #fff;
			font-size: 14px;
			text-transform: uppercase;

			a {
				color: #fff;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.smart__header {
		text-transform: uppercase;
	}

	@media screen and (max-width: 900px) {
		nav {
			display: none;
		}
	}
</style>
