<template>
	<div class="application">

		<h2>Přihláška na kroužek</h2>

		<p class="alert">
			Vážení rodiče, v případě, že od nás neobdržíte na Váš email potvrzující odpověď o úspěšném
			přihlášení Vašeho dítěte do kroužku, prosím, kontaktujte nás na e-mailu info@krouzky-kamarad.cz
			nebo na tel. čísle +420 605 057 005. Pokud přihlašujete své dítě do více kroužků, vyplňte, prosím,
			přihlášku na každý kroužek zvlášť, nebo do poznámky první přihlášky uveďte další kroužky, o které máte zájem.
			Mockrát Vám děkujeme.
		</p>

		<div class="application__board">
			<h3 class="application__board__title">Vyberte typ školy</h3>
			<div class="row row--justify-start row--pl-30 row--input-100">
				<p-check name="check" class="p-default" color="primary" v-model="applicationModel.zakladni" v-on:change="selectZakladni">Základní</p-check>
				<p-check name="check" class="p-default" color="primary" v-model="applicationModel.materska" v-on:change="selectMaterska">Mateřská</p-check>
			</div>

			<h3 class="application__board__title">Vyberte zaměření</h3>
			<div class="row row--input-100">
				<p-check
					v-for="(type, idx) in getTypes()"
					:key="idx"
					name="check"
					class="p-default"
					color="primary"
					v-model="applicationModel.itemType[idx]"
				>{{type.title}}</p-check>
			</div>

			<h3 class="application__board__title">Vyberte kroužek</h3>
			<div class="row row--justify-start">
				<div class="row--full">
					<CustomSelect ref="krouzek" :options="getActivities()" v-model="applicationModel.krouzek" />
				</div>
			</div>

			<div class="row">
				<div class="row--full">
					<div class="application__board__info">Přihlašovací údaje</div>
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>Jméno a příjmení dítěte</label>
					<input type="text" v-model="applicationModel.nameSurname" />
				</div>
				<div class="row--half">
					<label>Datum narození dítěte</label>
						<input type="text" v-model="applicationModel.rc" />
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>Ulice</label>
					<input type="text" v-model="applicationModel.street" />
				</div>
				<div class="row--half">
					<label>Číslo popisné</label>
						<input type="text" v-model="applicationModel.houseNumber" />
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>Město</label>
					<input type="text" v-model="applicationModel.city" />
				</div>
				<div class="row--half">
					<label>PSČ</label>
						<input type="text" v-model="applicationModel.psc" />
				</div>
			</div>

			<div class="row row--justify-start">
				<div class="row--half">
					<label>Jméno zákonného zástupce</label>
					<input type="text" v-model="applicationModel.parentName" />
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>E-mail</label>
					<input type="text" v-model="applicationModel.email" />
				</div>
				<div class="row--half">
					<label>Telefon (např. +420603200300)</label>
					<input type="text" v-model="applicationModel.telephone" />
				</div>
			</div>

			<div class="row" v-if="applicationModel.materska === true">
				<div class="row--half">
					<label>Název mateřské školy</label>
					<input type="text" v-model="applicationModel.msName" />
				</div>
				<div class="row--half">
					<label>Název oddělení (např. Berušky)</label>
					<input type="text" v-model="applicationModel.msDepartment" />
				</div>
			</div>

			<div class="row" v-if="applicationModel.zakladni === true">
				<div class="row--half">
					<label>Název školy</label>
					<input type="text" v-model="applicationModel.zsName" />
				</div>
				<div class="row--half">
					<label>Třída (např. 3.A.)</label>
					<input type="text" v-model="applicationModel.zsClass" />
				</div>
			</div>

			<div class="row row--justify-start">
				<div class="row--half">
					<label>Město (vyberte z nabídky</label>
					<CustomSelect :options="getCities()" v-model="applicationModel.city2" />
				</div>
				<div class="row--half" v-if="applicationModel.zakladni === true">
					<label>Název družiny (např. Slůňata, nepovinné)</label>
					<input type="text" v-model="applicationModel.druzina" />
				</div>
			</div>

			<div class="row">
				<div class="row--half">
					<label>Poznámka</label>
					<textarea rows="5" v-model="applicationModel.comment"></textarea>
				</div>
				<div class="row--half row--submit">
					<input type="submit" value="Přihlásit" @click="submit" />
				</div>
			</div>

			<div class="info" @click="setSouhlas">
				<div class="checkbox">
					<p-check name="check" class="p-default" color="primary" v-model="applicationModel.souhlas"></p-check>
				</div>
				<div class="text">
					Potvrzuji, že dítě je zdravotně způsobilé k absolvování dětských kroužků Kamarád a že jsem se seznámil/a s
					náplní kroužků a Provozním řádem Kamarád s.r.o. uvedeném na tomto webu. Dále souhlasím jako zákonný zástupce s
					tím, aby osobní údaje vyplněné v kontaktním formuláři byly evidovány společností Dětské kroužky Kamarád s.r.o.,
					se sídlem: Vraný 167, 273 73 Vraný, IČO: 08987181 pro účely komunikace s účastníkem dětských kroužků Kamarád a
					jeho zákonným zástupcem (viz <a target="_blank" href="https://krouzky-kamarad.cz/docs/rodice.pdf">Provozní řád Kamarád s.r.o. a GDPR</a>)
				</div>
			</div>

			<div class="sended" v-if="sended">
				Děkujeme. Vaše přihláška byla odeslána. Co nejdříve se Vám ozveme.
			</div>

			<div class="errors" v-if="errors.length > 0">
				<ul>
					<li
						v-for="(error, id) in errors"
						:key="id"
					>{{ error }}</li>
				</ul>
			</div>

		</div>
	</div>
</template>

<script>

	import CustomSelect from '../form/CustomSelect';
	import { ms, zs } from '../../data/offer';
	import ApplicationModel from '../../data/ApplicationModel';
	import emailjs from 'emailjs-com';

	export default {
		name: 'Application',
		data() {
			return {
				sended: false,
				errors: [],
				applicationModel: new ApplicationModel(this.$route.params.subsection, this.$route.params.detail),
			};
		} ,
		methods: {
			setSouhlas: function() {
				this.applicationModel.souhlas = !this.applicationModel.souhlas;
			},
			getTypes: function() {
				let data = [];
				if (this.applicationModel.zakladni) {
					data = [...data, ...zs];
				}

				if (this.applicationModel.materska) {
					data = [...data, ...ms];
				}

				const insertedTitles = [];
				const distinctedData = [];
				data.forEach((item, idx) => {
					if (insertedTitles.includes(item.title) === false) {
						distinctedData.push(item);
						insertedTitles.push(item.title);
					}

					if (item.title === this.$route.params.subsubsection) {
						this.applicationModel.itemType[idx] = true;
					}
				});

				return distinctedData;
			},
			getActivities: function() {
				let activities = [];

				const types = this.getTypes();
				Object.keys(this.applicationModel.itemType).map(itemKey => {
					if (this.applicationModel.itemType[itemKey] === true) {
						if (types[itemKey]) {
							types[itemKey].list.map(item => {
								activities.push({
									value: item.title,
									key: item.page
								});
							});
						}
					}
				});

				return activities;
			},
			getCities: function() {
				return [
					{ key: '', value: '' },
					{ key: 'Praha', value: 'Praha' },
					{ key: 'Praha Západ', value: 'Praha Západ' },
					{ key: 'Praha Východ', value: 'Praha Východ' },
					{ key: 'Brno', value: 'Brno' },
					{ key: 'Středočeský kraj', value: 'Středočeský kraj' }
				];
			},
			selectZakladni: function (event) {
				if (event) {
					this.applicationModel.materska = false;
				}
			},
			selectMaterska: function (event) {
				if (event) {
					this.applicationModel.zakladni = false;
				}
			},
			submit: function() {

				this.errors = [];
				const errors = this.applicationModel.validate();
				if (errors.length > 0) {
					this.errors = errors;
					return;
				}

				if (this.sended) {
					return;
				}

				this.sended = true;
				emailjs.send('mailgun','kamarad_pro_rodi_e', { email: this.applicationModel.email}, 'user_WvojWwBYhCYL2ExDN8vTy');
				emailjs.send('mailgun','template_FvneWsMI', this.applicationModel.getEmailParams(this.getTypes()), 'user_WvojWwBYhCYL2ExDN8vTy')
					.then(() => {
						this.applicationModel.reset();
						this.$refs.krouzek.setSelected({ value: null });
					}, () => {
						this.errors.push('Vyskytly se problémy s odesláním přihlášky. Kontaktujte nás prosím na telefonu +420 605 525 001');
					}
				);
			}
		},
		components: {
			CustomSelect
		},
	}
</script>

<style lang="scss">

	$pretty--color-primary:#4DBAE7;
	@import '~pretty-checkbox/src/pretty-checkbox.scss';

	.pretty {
		margin: 10px 0;

		.state {
			label {
				padding-right: 20px;
				display: inherit;

				&::before {
					left: auto;
					right: 0px;
				}
				&::after {
					left: auto;
					right: 0px;
				}
			}
		}
	}

	.application {
		padding-top: 45px;
		text-align: center;

		.alert {
			font-weight: bold;
		}

		&__board {
			border: 1px solid black;
			background-color: #fff;
			width: 480px;
			margin: 0 auto;
			padding: 30px 40px;
			text-align: left;

			&__title {
				font-size: 14px;
				text-align: left;
			}

			&__info {
				color: #65C4EA;
				font-size: 10px;
			}
		}

		&--full {
			width: 100%;
		}

		&--half {
			width: 50%;
		}

		&--middle {
			width: 30%;
		}

		&--short {
			width: 15%;
		}
	}

	.info {
		display: flex;

		.checkbox {
			width: 30px;
		}

		.text {
			width: 100%;
			padding: 20px 0 0 20px;
			font-size: 11px;
			line-height: 1.1;
		}
	}

	.errors {

		background-color: #CA4B40;
		border-radius: 15px;
		padding: 20px;
		margin-top: 20px;

		ul {
			margin: 0px 0;
			padding: 0px;
			list-style: none;

			li {
				font-size: 16px !important;
				color: #fff;
				padding: 0px 0px 10px;
			}
		}
	}

	.sended {
		background-color: #82C9ED;
		border-radius: 15px;
		padding: 20px;
		margin-top: 20px;
		color: #fff;
		font-size: 16px;
		text-align: center;
		font-weight: bold;
	}

	.row {
		width: 100%;
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		&--input-100 {
			input {
				width: 100% !important;
			}
		}

		&--justify-start {
			justify-content: start;
		}

		&--pl-30 {
			padding-left: 30px;
		}

		&--no-margin {
			margin-bottom: 0px;
		}

		&--full {
			width: 100%;
		}

		&--half {
			width: 50%;
		}

		&--submit {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 10px;

			input {
				color: #fff !important;
				background-color: #4DBAE7;
				border-bottom: 3px solid #3594D4 !important;
				border-left: 0px !important;
				border-right: 0px !important;
				border-top: 0px !important;
				width: 90% !important;
				height: 30px;
				position: relative;
				top: 3px;
			}
		}

		label {
			display: block;
			font-size: 12px;
		}

		input {
			border: 1px solid #999999;
			border-radius: 3px;
			padding: 6px;
			color: #65C4EA;
			width: 85%;
		}

		textarea {
			width: 85%;
			font-family: 'Raleway Regular', arial;
		}
	}

	@media screen and (max-width: 560px) {
		.application {
			&__board {
				width: 96%;
				padding: 0px;
				border: 0px;
				padding: 2%;
			}
		}

		.row--pl-30 {
			padding-left: 0px;
		}

		.row--half {
			width: 95%;
			padding-left: 5%;
		}
	}
</style>
