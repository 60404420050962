<template>
	<div class="header">
		<TopMenu :sections="sections" />
		
		<router-link to="/">
			<div class="header__logo">
				<img src="../assets/logo_260_142.png" alt="" class="header__logo--fix" />
			</div>
		</router-link>
	</div>
</template>

<script>

import TopMenu from './TopMenu';

export default {
	name: 'Header',
	props: {
		sections: Array
	},
	components: {
		TopMenu
	}
}
</script>

<style lang="less">
	.header {
		background: #37B1E6;
		height: 165px;

		&__logo {
			width: 260px;
			margin: 0 auto;
			padding-top: 30px;
			position: relative;
			z-index: 1;

			&--fix {
				position: relative;
				left: 25px;
			}
		}
	}

	@media screen and (max-width: 260px) {
		.header {

			&__logo {
				width: 100%;
			}
		}
	}
</style>