import { render, staticRenderFns } from "./FooterMenu.vue?vue&type=template&id=46b16eaa&scoped=true&"
import script from "./FooterMenu.vue?vue&type=script&lang=js&"
export * from "./FooterMenu.vue?vue&type=script&lang=js&"
import style0 from "./FooterMenu.vue?vue&type=style&index=0&id=46b16eaa&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46b16eaa",
  null
  
)

export default component.exports