<template>
	<div class="homepage">
		<div class="homepage__menu">
			<HomepageColumn
				v-for="item in items"
				:key=item.id
				:homepageItem=item
			/>
		</div>

		<div class="homepage__image">
			<img src="../../assets/childrens.png" alt="" />
		</div>
	</div>
</template>

<script>

	import HomepageColumn from './HomepageColumn';

	export default {
		name: 'Homepage',
		data: function () {
			return {
				items: [
					{
						id: 1,
						title: 'Nabídka kroužků',
						description: 'Pestrá a moderní nabídka sportovních, tanečních, edukativních, jazykových, divadelních, hudebních, výtvarných a tvořivých kroužků.',
						page: 'nabidka-krouzku',
						image: {
							src: 'img/homepage/krouzky.png',
							alt: ''
						}
					},
					{
						id: 2,
						title: 'Přihláška',
						description: 'Prosíme o vyplnění online přihlášky. Děkujeme za Váš zájem a důvěru.',
						page: 'vyber-prihlasky',
						image: {
							src: 'img/homepage/prihlaska.png',
							alt: ''
						}
					},
					{
						id: 3,
						title: 'PŘÍMĚSTSKÉ TÁBORY A TEMATICKÉ AKTIVITY',
						description: 'Široká nabídka příměstských táborů, dětských aktivit a workshopů pro děti.',
						page: 'primestske-tabory-a-tematicke-aktivity',
						image: {
							src: 'img/homepage/tabory-a-activity.png',
							alt: ''
						}
					}
				]
			}
		},
		components: {
			HomepageColumn
		}
	}
</script>

<style lang="less">

	.homepage {

		&__menu {
			width: 900px;
			margin: 0 auto;
			min-height: 400px;
			display: flex;
			justify-content: space-around;

			&__column {
				position: relative;
				width: 30%;
				padding: 0 45px;
				text-align: center;

				h2 {
					font-family: 'Caveat Brush';
					text-transform: uppercase;
					color: #BA3F8C;
					font-size: 30px;
					letter-spacing: 0.23px;
					margin: 35px 0 10px;
				}

				p {
					margin: 0 0 25px;
					line-height: 1.4;
					color: #2E2A4D;
				}

				img {
					width: 270px;
				}

				&:nth-child(1) {
					top: 30px;
				}

				&:nth-child(3) {
					top: 75px;
				}

				a {
					text-decoration: none;
				}
			}
		}

		&__image {
			width: 420px;
			margin: 0 auto;
			position: relative;
			top: 85px;
		}
	}

	@media screen and (max-width: 900px) {
		.homepage {

			&__menu {
				width: 100%;
				flex-direction: column;
				align-items: center;

				&__column {
					&:nth-child(2) {
						top: 30px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 420px) {
		.homepage {

			&__image {
				width: 100%;
			}
		}
	}

</style>
