export default class ApplicationModel
{
	constructor(subsection, krouzek) {
		this.zakladni = subsection === 'zs';
		this.materska = subsection === 'ms';
		this.itemType = {};
		this.krouzek = krouzek;
		this.nameSurname = '';
		this.rc = '';
		this.street = '';
		this.houseNumber = '';
		this.psc = '';
		this.parentName = '';
		this.email = '';
		this.telephone = '';
		this.msName = '';
		this.msDepartment = '';
		this.zsName = '';
		this.zsClass = '';
		this.city = '';
		this.city2 = null;
		this.druzina = '';
		this.comment = '';
		this.souhlas = false;
	}

	reset() {
		this.zakladni = false;
		this.materska = false;
		this.itemType = {};
		this.krouzek = undefined;
		this.type = {};
		this.nameSurname = '';
		this.rc = '';
		this.street = '';
		this.houseNumber = '';
		this.psc = '';
		this.parentName = '';
		this.email = '';
		this.telephone = '';
		this.msName = '';
		this.msDepartment = '';
		this.zsName = '';
		this.zsClass = '';
		this.city = '';
		this.city2 = '';
		this.druzina = '';
		this.comment = '';
		this.souhlas = false;
	}

	validate() {
		const errors = [];
		if (this.zakladni === false && this.materska === false) { errors.push('Zadejte, prosím, typ školy.'); }
		if (Object.keys(this.itemType).length === 0) { errors.push('Zadejte, prosím, typ zaměření.'); }
		if (!this.krouzek) { errors.push('Zadejte, prosím, krouzek.'); }
		if (!this.nameSurname) { errors.push('Zadejte, prosím, jméno a příjmení dítěte.'); }
		if (!this.rc) { errors.push('Zadejte, prosím, datum narození dítěte.'); }
		if (!this.street) { errors.push('Zadejte, prosím, ulici.'); }
		if (!this.houseNumber) { errors.push('Zadejte, prosím, číslo popisné.'); }
		if (!this.psc) { errors.push('Zadejte, prosím, PSČ.'); }
		if (!/\d{3} ?\d{2}/.test(this.psc)) { errors.push('Zadejte, prosím, správný tvar PSČ, např. 530 02.'); }
		if (!this.parentName) { errors.push('Zadejte, prosím, jméno zákoného zástupce.'); }
		if (!this.email) { errors.push('Zadejte, prosím, e-mail.'); }
		if (!/[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(this.email)) { errors.push('Zadejte, prosím, správný tvar e-mailu, např. info@krouzky-kamarad.cz.'); }
		if (!this.telephone) { errors.push('Zadejte, prosím, telefon.'); }
		if (!/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/.test(this.telephone)) { errors.push('Zadejte, prosím, správný tvar telefonu, např. +420603200300.'); }

		if (this.materska) {
			if (!this.msName) { errors.push('Zadejte, prosím, jméno mateřské školy.'); }
			if (!this.msDepartment) { errors.push('Zadejte, prosím, oddělení.'); }
		}

		if (this.zakladni) {
			if (!this.zsName) { errors.push('Zadejte, prosím, jméno základní školy.'); }
			if (!this.zsClass) { errors.push('Zadejte, prosím, třídu.'); }
		}
		if (!this.city) { errors.push('Zadejte, prosím, město.'); }
		if (!this.city2) { errors.push('Zadejte, prosím, město.'); }
		if (this.souhlas === false) { errors.push('Zaškrtněte, prosím, Souhlas s Provozním řádem a GDPR.'); }

		return errors;
	}

	getEmailParams(types) {
		let txtTypes = '';
		Object.keys(this.itemType).forEach(id => {
			if (this.itemType[id] === true) {
				txtTypes += types[id].title + ', ';
			}
		})

		return {
			typSkoly: this.zakladni ? 'Základní' : 'Mateřská',
			itemType: txtTypes.substring(0, txtTypes.length - 2),
			krouzek: this.krouzek,
			nameSurname: this.nameSurname,
			rc: this.rc,
			street: this.street,
			houseNumber: this.houseNumber,
			psc: this.psc,
			parentName: this.parentName,
			email: this.email,
			telephone: this.telephone,
			city: this.city,
			msName: this.msName,
			msDepartment: this.msDepartment,
			zsName: this.zsName,
			zsClass: this.zsClass,
			druzina: this.druzina,
			comment: this.comment
		};
	}
}
