<template>
	<div class="footer">
		<div class="footer__content">
			<div class="footer_logo">
				<img src="../assets/logo_170_93.png" alt="" />
			</div>
			<div class="footer_menu">
				<FooterMenu :sections="slicedSections" />
			</div>
			<div class="footer_contact">
				<FooterMenu :sections="contact" />
			</div>
		</div>
		<div class="footer__info">
      Sídlo: Dětské kroužky Kamarád s.r.o., Na Florenci 1686/9, 110 00 Praha 1 - Nové Město. IČO: 08987181. Všechna práva vyhrazena, {{ (new Date()).getFullYear() }}
    </div>
	</div>
</template>

<script>

	import FooterMenu from './FooterMenu';

	export default {
		name: 'Footer',
		data: function () {
			return {
				contact: [
					{
						title: 'kontakt',
						url: { sections: 'info', subsection: 'kontakt' }
					},
					{ title: '+420 605 525 001' },
					{ title: 'info@krouzky-kamarad.cz' },
					{ title: 'facebook' }
				],
				slicedSections: this.sections.slice(0, -1)
			}
		},
		props: {
			sections: Array
		},
		components: {
			FooterMenu
		}
	}
</script>

<style lang="less" scoped>
	.footer {
		background-color: #fff;
		min-height: 243px;
		margin-bottom: 50px;

		&__content {
			display: flex;
			justify-content: space-between;
			width: 560px;
			margin: 0 auto;
			margin-top: 25px;
		}

		&__info {
			font-size: 10px;
			width: 100%;
			text-align: center;
			margin-top: 20px;
		}
	}

	@media screen and (max-width: 560px) {
		.footer {
			&__content {
				width: 100%;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}
</style>
